import AdminFooter from "../components/AdminFooter";
import AdminHeader from "../components/AdminHeader";

const AdminLayout = ({ children }) => {
  return (
    <div className="md:container mx-auto">
      <AdminHeader />
      <main>{children}</main>
      <AdminFooter />
    </div>
  );
};

export default AdminLayout;
