import Footer from "../components/Footer";
import LoginHeader from "../components/LoginHeader";

const LoginLayout = ({ children }) => {
  return (
    <div className="md:container mx-auto">
      <LoginHeader />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default LoginLayout;
