import { Link } from "react-router-dom";

const QuickLink = ({ text, to, handleLinkClick }) => {
  return (
    <li>
      <Link
        to={to}
        className="hover:underline cursor-pointer"
        onClick={handleLinkClick}
      >
        {text}
      </Link>
    </li>
  );
};

export default QuickLink;
