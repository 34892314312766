import React from 'react'
import Testimonials from '../components/HomePage/Testimonials';
import PricingPlan from '../components/PricingPlan';

const Pricing = () => {
  return (
    <div className="bg-[url('https://tfspublic.s3.ap-south-1.amazonaws.com/images/pricing/background.png')] bg-no-repeat h-full -mt-5 mb-32 bg-contain">
      <div className='flex flex-col justify-center items-center '>
        <p className='text-secondary text-h5 font-bold	mt-36'>Pricing Plan</p>
        <p className='text-primary font-bold mt-7 text-center font-lora text-h2 mobile:text-h4'>Invest in Your Success:<span className='text-secondary'> Pricing Plans<br />
          Tailored for Your Journey</span></p>
        <p className='text-h5 font-[300] mobile:text-center font-poppins text-primary mt-10 mb-32'>Choose the Plan That Fits Your Goals and Budget</p>
        <PricingPlan />
        <Testimonials />
      </div>
    </div>
  )
}

export default Pricing
