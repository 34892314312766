import { ErrorMessage, Field, Form, Formik } from 'formik';
import profileBg from '../../Images/profileBg.png';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo, profileChangeReq, profileChangeReqList } from '../../store/actions/auth.action';
import axios from 'axios';
import * as Yup from 'yup';
import Pagination from '../../components/Resources/Pagination';

const profileValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required'),
  lastname: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  mobileno: Yup.string().required('Mobile No is required'),
  // photo: Yup.mixed().required('A photo is required'),
  // gstnumber: Yup.string()
  //   .required('GST Number is required')
  //   .matches(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z\d]{1}$/, 'Invalid GST Number format'),
});


const ProfileDetails = ({ openTab }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [preview, setPreview] = useState(null); // State to hold the preview URL
  const itemsPerPage = 5;
  const { profileData, loader, profilechangelist } = useSelector((state) => state.auth);
  const [gstUpdateCount, setGstUpdateCount] = useState(0);

  useEffect(() => {
    const payload = {
      requestId: '',
      requestStatus: '',
      pageNo: currentPage,
      perPageResults: itemsPerPage,
    };
    dispatch(getProfileInfo());
    dispatch(profileChangeReqList(payload));
    const storedGstUpdateCount = localStorage.getItem('gstUpdateCount');
    if (storedGstUpdateCount) {
      setGstUpdateCount(parseInt(storedGstUpdateCount, 10));
    }
  }, [dispatch, openTab, currentPage]);

  const calculateRemainingDays = () => {
    if (profileData && profileData.subscriptionEndDate) {
      const endDate = new Date(profileData.subscriptionEndDate);
      const currentDate = new Date();
      const differenceInTime = endDate.getTime() - currentDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays >= 0 ? differenceInDays : 0;
    }
    return 0;
  };
  const calculateDaysSinceStart = () => {
    if (profileData && profileData.subscriptionStartDate) {
      const startDate = new Date(profileData.subscriptionStartDate);
      const currentDate = new Date();
      const differenceInTime = currentDate.getTime() - startDate.getTime();
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
      return differenceInDays >= 0 ? differenceInDays : 0;
    }
    return 0;
  };

  const renderUpgradeOrRenewButton = () => {
    const remainingDays = calculateRemainingDays();
    const daysSinceStart = calculateDaysSinceStart();

    if (daysSinceStart <= 30) {
      return (
        <button className="bg-green-500 text-white px-2 mt-3 mobile:h-auto mobile:py-4 rounded-[39px] py-2">
          Upgrade Now
        </button>
      );
    } else if (remainingDays <= 20) {
      return (
        <button className="bg-green-500 text-white mobile:h-auto mobile:py-4 rounded-[39px] py-2">
          Renew Now
        </button>
      );
    }
    return null;
  };

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const signedUrlResponse = await axios.get(
        `https://rudf4zn65l.execute-api.ap-south-1.amazonaws.com/dev/getSignedUrl?mediaType=profilePhoto&fileName=${values.photo.name}`,
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }
      );

      if (signedUrlResponse.status === 200) {
        const signedUrl = signedUrlResponse.data.data.s3SignedUrl;
        const imageUrl = signedUrl.split('?')[0];

        const photo = values.photo;
        await axios.put(signedUrl, photo, {
          headers: {
            'Content-Type': photo.type,
          },
        });

        const payload = {
          emailAddress: values.email,
          mobileNumber: values.mobileno,
          firstName: values.firstname,
          lastName: values.lastname,
          profilePhotoUrl: imageUrl,
          gstNo: values.gstnumber,
          action: 'SUBMIT',
        };

        dispatch(profileChangeReq(payload));
        // Increment the GST update count
        const newGstUpdateCount = gstUpdateCount + 1;
        setGstUpdateCount(newGstUpdateCount);
        localStorage.setItem('gstUpdateCount', newGstUpdateCount.toString());
      }
    } catch (error) {
      console.error('Error uploading photo', error);
      // setFieldError('photo', 'Failed to upload photo');
    } finally {
      setSubmitting(false);
    }
  };

  const cancelReq = (row) => {
    const payload = {
      emailAddress: row?.email,
      mobileNumber: row?.mobileno,
      firstName: row?.firstname,
      lastName: row?.lastname,
      action: 'CANCEL',
    };
    dispatch(profileChangeReq(payload));
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue('photo', file);
    setPreview(URL.createObjectURL(file));
  };

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const totalPages = Math.ceil(profilechangelist?.userList?.length / 5);

  return (
    <>
      {loader ? (
        <div className="p-10">
          <div className="flex items-center gap-5">
            <Skeleton circle={true} height={150} width={150} />
            <Skeleton width={150} height={50} />
          </div>
          <div className="grid grid-cols-3 gap-7 mt-10">
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
          <div className="grid grid-cols-3 gap-7 mt-5">
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
          <div className="grid grid-cols-3 gap-7 mt-5">
            <Skeleton height={50} />
          </div>
        </div>
      ) : (
        <>
          <Formik
            initialValues={{
              firstname: profileData?.firstName || '',
              lastname: profileData?.lastName || '',
              email: profileData?.emailAddress || '',
              mobileno: profileData?.mobileNumber || '',
              batchno: profileData?.batchNo,
              subscriptionType: profileData?.subscriptionType,
              enddate: profileData?.subscriptionEndDate,
              photo: null,
              gstnumber: profileData?.gstNo || '',
            }}
            enableReinitialize
            validationSchema={profileValidationSchema}
            onSubmit={onSubmit}
          >
            {({ isValid, dirty, setFieldValue }) => (
              <Form className="w-full mobile:rounded-none mobile:p-3 bg-white rounded-[40px] h-full">
                <div className="p-10 mobile:p-0">
                  <div className="flex mobile:flex-col items-center gap-5">
                    <div>
                      <div className="relative">
                        <div className="rounded-full h-[150px] relative z-50 w-[150px] mobile:w-[90px] mobile:h-[90px] font-poppins flex items-center justify-center text-white text-7xl mobile:text-3xl font-normal">
                          {!preview && profileData?.firstName?.charAt(0).toUpperCase()}
                        </div>
                        <img src={profileBg} alt="" className="absolute inset-0 object-cover rounded-full h-full w-full" />
                        {preview && <img src={preview} alt="Profile Preview" className="absolute inset-0 object-cover rounded-full h-full w-full" />}
                      </div>
                    </div>
                    <div className="border-2 border-primary rounded-[37px] py-3 px-9 text-primary font-poppins text-xl font-medium">
                      <label htmlFor="photo" className="cursor-pointer">
                        Upload Photo
                      </label>
                      <input
                        type="file"
                        id="photo"
                        name="photo"
                        className="hidden"
                        onChange={(event) => handleFileChange(event, setFieldValue)}
                      />
                      <ErrorMessage name="photo" component="div" className="text-red-500" />
                    </div>
                  </div>
                  <hr className=" mobile:mt-10 border-t-2 color-secondary opacity-16  laptop:hidden" />

                  <div className="grid grid-cols-3 mobile:grid-cols-1 mobile:p-3 mobile:gap-3 gap-7 mt-10">
                    <div className="mb-5">
                      <label htmlFor="firstname" className="text-base font-normal font-poppins block mb-2 text-primary">
                        First Name <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="text"
                        id="firstname"
                        name="firstname"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                      />
                      <ErrorMessage name="firstname" component="div" className="text-red-500" />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="lastname" className="text-base font-normal font-poppins block mb-2 text-primary">
                        Last Name <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="text"
                        id="lastname"
                        name="lastname"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                      />
                      <ErrorMessage name="lastname" component="div" className="text-red-500" />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="email" className="text-base font-normal font-poppins block mb-2 text-primary">
                        Email <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                      />
                      <ErrorMessage name="email" component="div" className="text-red-500" />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 mobile:grid-cols-1 mobile:p-3 mobile:gap-3 gap-7 mt-5 items-center">
                    <div className="mb-5">
                      <label htmlFor="mobileno" className="text-base font-normal font-poppins block mb-2 text-primary">
                        Mobile No <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="text"
                        id="mobileno"
                        name="mobileno"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                      />
                      <ErrorMessage name="mobileno" component="div" className="text-red-500" />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="batchno" className="text-base font-normal font-poppins block mb-2 text-primary">
                        Batch No <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="text"
                        id="batchno"
                        name="batchno"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                        readOnly
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="subscriptionType" className="text-base font-normal font-poppins block mb-2 text-primary">
                        Subscription Type <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="text"
                        id="subscriptionType"
                        name="subscriptionType"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 mobile:grid-cols-1 mobile:p-3  mobile:gap-3 gap-7 mt-5 items-center">
                    <div className="mb-5">
                      <label htmlFor="enddate" className="text-base font-normal font-poppins block mb-2 text-primary">
                        End Date <span className="text-secondary">*</span>
                      </label>
                      <Field
                        type="text"
                        id="enddate"
                        name="enddate"
                        className="border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder=""
                        readOnly
                      />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="remainingdays" className="text-base font-normal font-poppins block mb-2 text-primary">
                        Remaining Days
                      </label>
                      <Field
                        type="text"
                        id="remainingdays"
                        value={calculateRemainingDays()}
                        name="remainingdays"
                        className="border border-primary text-[#21428486] bg-[#2142841a] text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary"
                        placeholder="End Date"
                        disabled
                      />
                      <ErrorMessage name="remainingdays" component="div" className="text-red-500" />
                    </div>
                    <div className="mb-5">
                      <label htmlFor="gstnumber" className="text-base font-normal font-poppins block mb-2 text-primary">
                        GST Number
                      </label>
                      <Field
                        type="text"
                        id="gstnumber"
                        name="gstnumber"
                        placeholder="GST Number"
                        className={`border border-primary text-primary text-base font-semibold font-poppins rounded-[39px] focus:ring-0 focus:border-primary block w-full p-2.5 placeholder:text-primary ${gstUpdateCount >= 3 ? 'bg-gray-200 cursor-not-allowed' : ''}`}
                        disabled={gstUpdateCount >= 3}
                      />
                      <ErrorMessage name="gstnumber" component="div" className="text-red-500" />
                    </div>

                  </div>
                  <div className="flex justify-center  mobile:p-3 mt-5 mobile:w-full">
                    <button
                      type="submit"
                      className={`text-white bg-primary uppercase mobile:w-full rounded-[39px] text-lg px-10 py-2 `}
                    // disabled={!isValid }
                    >
                      Save the update
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {renderUpgradeOrRenewButton()}

          <div className="mt-10">
            <h2 className="text-2xl font-medium text-primary w-full text-center">Request Status</h2>
            <div className="mt-5">
              {profilechangelist?.userList?.length > 0 ? (
                <>
                  <table className="w-full text-left ">
                    <thead>
                      <tr className="bg-white text-primary ">
                        <th className="px-6 py-3 ">Sr.No</th>
                        <th className="px-6 py-3 ">First Name</th>
                        <th className="px-6 py-3 ">Last Name</th>
                        <th className="px-6 py-3 ">Email</th>
                        <th className="px-6 py-3 ">Mobile No</th>
                        <th className="px-6 py-3 ">Status</th>
                        <th className="px-6 py-3 ">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {profilechangelist?.userList?.map((row, index) => (
                        <tr key={index} className="border-b text-primary font-poppins">
                          <td className="px-6 py-4">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                          <td className="px-6 py-4">{row.firstName}</td>
                          <td className="px-6 py-4">{row.lastName}</td>
                          <td className="px-6 py-4">{row.emailAddress}</td>
                          <td className="px-6 py-4">{row.mobileNumber}</td>
                          <td className="px-6 py-4">{row.requestStatus}</td>
                          <td className="px-6 py-4 text-lg text-center flex justify-center items-center">
                            {row?.requestStatus === "SUBMITTED" && (
                              <div className="cursor-pointer" onClick={() => cancelReq(row)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM9.70164 8.64124C9.40875 8.34835 8.93388 8.34835 8.64098 8.64124C8.34809 8.93414 8.34809 9.40901 8.64098 9.7019L10.9391 12L8.64098 14.2981C8.34809 14.591 8.34809 15.0659 8.64098 15.3588C8.93388 15.6517 9.40875 15.6517 9.70164 15.3588L11.9997 13.0607L14.2978 15.3588C14.5907 15.6517 15.0656 15.6517 15.3585 15.3588C15.6514 15.0659 15.6514 14.591 15.3585 14.2981L13.0604 12L15.3585 9.7019C15.6514 9.40901 15.6514 8.93414 15.3585 8.64124C15.0656 8.34835 14.5907 8.34835 14.2978 8.64124L11.9997 10.9393L9.70164 8.64124Z" fill="#FFBB6B" />
                                </svg>
                              </div>
                            )}
                            {(row?.requestStatus === "REJECTED" || row?.requestStatus === "CANCELLED" || row?.requestStatus === "APPROVED") && ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    totalPages={totalPages}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </>
              ) : (
                <p>No profile change requests found.</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileDetails;
