import React, { useState } from "react";
import photoBg from "../../Images/photoBg.svg";
import { Link } from "react-router-dom";

const About = () => {
  const [readMore, setReadMore] = useState(false);

  // Handle "Read More" click
  const handleReadMore = () => {
    setReadMore(true);
  };

  // Handle "Read Less" click
  const handleReadLess = () => {
    setReadMore(false);
  };

  return (
    <div
      className={`bg-primary flex mobile:flex-col-reverse mobile:h-full mobile:p-4 p-24 mobile:-mt-9 mobile:z-30 relative mobile:rounded-t-[40px] rounded-t-[90px] max-w-full ${
        readMore ? "flex-col" : "flex-row"
      }`}
    >
      {/* 1 */}
      <div
        className={`flex-1 mobile:w-full mb-0 flex justify-center mobile:justify-center items-baseline relative `}
      >
        <img
          src={photoBg}
          alt=""
          className="mt-56 mobile:mt-32 mobile:p-0 absolute mobile:linebg"
        />
        <img
          src="https://tfspublic.s3.ap-south-1.amazonaws.com/images/Home+Page/Group+1321314670.png"
          alt="user"
          className="-mt-20 mobile:w-[410px] w-fit md:h-[650px] mobile:-mt-20 mobile:p-0"
        />
      </div>

      <div className="flex-1 mobile:w-full">
        <p className="text-h2 font-[700] text-[#FFF] font-lora mobile:text-4xl mobile:p-3">
          About <span className="text-secondary">Nishant Arora</span>
        </p>
        <hr className="w-[15%] mobile:w-[20%] mt-5 border-[3px] border-[#FFBB6B]" />
        <div className="w-full text-justify text-[#FFF] font-poppins font-light w-[73%]">
          <p className="mt-5">Greetings</p>
          <p className="mt-5">My Fellow Learners and Traders!!</p>
          <p className="mt-5">
            My Name is Nishant Arora. Let me introduce myself to you.
          </p>
          <p className="mt-4">
            If I have to explain myself in one word. I would call myself an
            EXPLORER.I have been exploring and learning things on my own since
            childhood. I taught myself guitar, piano and mouth organ in the
            early days of my childhood. I did an MBA and was recruited by an MNC
            firm but soon realized that my calling is somewhere else. I thrive
            more with PEOPLE than PROCESS. I quit my high paying MNC job and
            took a plunge into sales, joining a company which was into a
            business of printer selling dealing with big clientele.
          </p>
          {!readMore && (
            <p
              className="text-secondary text-h5 font-poppins underline mt-5 tablet:text-[18px] mobile:text-[18px] cursor-pointer"
              onClick={handleReadMore}
            >
              Read More
            </p>
          )}
          {readMore && (
            <>
              <p className="mt-4">
                Soon I developed an understanding of business and became
                successful in my organization. I explored the entrepreneurial
                facets of my personality and went on a spree of setting up
                multiple successful businesses ranging from dealerships of
                multiple consumer companies to an event management firm.
              </p>
              <p className="mt-4">
                I am also an avid reader. I like to read about any subject. Be
                it: spirituality,medicine, politics, history, technology,
                anthropology. During one such reading spree, I came across a
                book on investing and trading. In no time, I had read multiple
                books on trading and realized this is my passion. I started
                trading/investing and became successful in a few years.
              </p>
              <p className="mt-4">
                However I still felt, something was missing from the picture
                perfect life – The last piece of the jigsaw – which completes
                the picture. I have realized that I enjoy working with people.
                Be it the sales job or setting up multiple successful
                businesses: One common thread running across was PEOPLE.
              </p>
              <p className="mt-4">
                I wanted to give back to society and help people. I decided to
                set up a learning platform which is unique and offers quality
                knowledge to the enthusiastic market learners.
              </p>
              <p className="mt-4">
                I have been privileged to spearhead two vibrant learning
                communities:
              </p>
              <ol className="list-decimal pl-5">
                <li>TFS ( Train for Success by Nishant Arora )</li>
                <li>TIC ( The Inner Circle )</li>
              </ol>
              <p className="mt-5">TFS – Train for Success</p>
              <p className="mt-4">
                With this endeavor, I intend to create a conducive environment
                that fosters learning and growth in the challenging arena of
                trading education and temperament building - a prerequisite to
                becoming a better individual and a trader.
              </p>
              <p className="mt-4">
                My philosophy is “You can’t DO until you BE” which means that
                before becoming successful in trading you have to become one
                such person, which itself is a continuous evolution process. We
                at TFS focus on learning which helps you become that person. We
                have a dedicated and focused community of 20,000 members who I
                engage with my posts, audios, videos, podcasts and learning
                material thereby not only creating a fertile mind BUT also plant
                seeds for future success.
              </p>
              <p className="mt-5">TIC – The Inner Circle</p>
              <p className="mt-4">
                After I created TFS, I realized the need for a closer
                association with serious learners. There have been multiple
                requests from the fellow members of TFS who wanted to interact
                more frequently and intensively in a closed environment. I felt
                this is an organic evolution for any community where there are
                few more serious and enthusiastic members who with their hard
                work and dedication became the “First among the equals”. With a
                thriving community of 20,000 members, it was natural to happen.
              </p>
              <p className="mt-4">
                I am very passionate about teaching who caters as much to the
                needs of the last student as to the needs of the first one. I
                felt the need to create a dedicated community where we nurture
                the raw talent emerging out of TFS and who are ready to move to
                the next level.
              </p>
              <p className="mt-5">
                I have created a dedicated community called TIC – The Inner
                Circle.
              </p>
              <p className="mt-4">
                TIC is a closed fraternity of likeminded people who are serious,
                enthusiastic learners who wish to go to the next level and want
                a closer association – a peep into my mind. The curriculum in
                TIC is quite rigorous and demands extreme hard work, dedication
                and discipline from the students. I share my thought process
                around my trades, life in general, trading psychology nuggets,
                my experiential insights through a gamut of distribution
                mechanisms like posts, audio snippets, video snippets, Chart
                video snippets(CVS), webinars etc. The material is deep and
                exhaustive in length.
              </p>
              <p className="mt-4">
                The admission to TIC is strictly based on referrals or organic
                evolution of students from the TFS Facebook group. The admission
                window opens only ONCE in a year as I believe in quality over
                quantity.
              </p>
              <p className="mt-4">
                TIC is a subscription based annual program where we have more
                than 2000 members actively engaging with me on 24*7 basis and I
                am proud to say that we have a tremendous renewal rate every
                year.
              </p>
              <p className="mt-4">Once a TICian, Always a TICian!!</p>
              <p className="mt-4">
                I welcome you all to my world of trading education and personal
                development. Together we will embark on the journey of your
                personal transformation, growth and success.
              </p>
            </>
          )}
          {readMore && (
            <p
              className="text-secondary text-h5 font-poppins underline mt-5 tablet:text-[18px] mobile:text-[18px] cursor-pointer"
              onClick={handleReadLess}
            >
              Read Less
            </p>
          )}
        </div>
        {/* 2 */}
        {/* <hr className="w-[15%] mobile:hidden mt-5 border-[2px] border-[#FFBB6B]" /> */}
        <div className="flex flex-wrap mobile:w-full mobile:flex-col items-center gap-5 mt-10">
          <Link
            to={"/aboutus"}
            className="py-3 mobile:w-full text-[#FFF] flex justify-center text-body items-center font-[600] text-[20px] font-poppins px-5 border-[3px] border-[#FFF] rounded-[35px]"
          >
            {"Learn More >>"}
          </Link>
          <Link
            to={"https://youtu.be/kclDCilaMas?si=0wlBj5Ra-TVg7k9k"}
            className="py-4 mobile:w-full mobile:mb-28 text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-5"
          >
            Watch Video
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
