import { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Pagination from "./Pagination";
import Skeleton from "react-loading-skeleton";

const podcastBaseUrl =
  "https://tfspublic.s3.ap-south-1.amazonaws.com/galleryAttachments/videos/";

const VideoRes = ({ galaryData, loader }) => {
  const filteredGalleryList = galaryData?.galleryList?.filter((item) =>
    item.attachments.some((attachment) => attachment.startsWith(podcastBaseUrl))
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 21;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredGalleryList?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // const paginate = pageNumber => {
  //     setCurrentPage(pageNumber);
  //     window.scrollTo(0, 0);
  // };

  return (
    <>
      {loader ? (
        <div className="text-center">
          <div role="status" className="grid grid-cols-3">
            <Skeleton height={270} count={1} />
          </div>
        </div>
      ) : (
        <>
          {currentItems?.length > 0 ? (
            <>
              <div className="grid grid-cols-3 tablet:grid-cols-2 flex-wrap mobile:grid-cols-1 gap-1 w-full">
                {currentItems.map((item) => (
                  //   <div
                  //     key={item.galleryId}
                  //     className="rounded-[32px] w-full border-[1px] glassbg2 border-white px-3 py-3"
                  //   >
                  <div
                    key={item.galleryId}
                    className="flex flex-col w-full justify-between h-full"
                  >
                    {/* <div>
                        <p className="text-h5 text-primary font-bold">
                          {item.title}
                        </p>
                        <p className="text-body font-poppins font-medium text-primary mt-3 mb-3">
                          Created on: {item.createdDate}
                        </p>
                      </div> */}
                    <div className="relative">
                      {item.attachments.map(
                        (attachment, index) =>
                          attachment.startsWith(podcastBaseUrl) && (
                            <div key={index} className="relative">
                              <video
                                controls
                                className="object-cover"
                                poster={""}
                                preload="auto"
                                style={{
                                  width: "100%",
                                  height: "270px",
                                  //   borderRadius: "30px",
                                }}
                              >
                                <source
                                  src={attachment + "#t=0.1"}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                  //   </div>
                ))}
              </div>

              {/* <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(filteredGalleryList?.length / itemsPerPage)}
                                paginate={paginate}
                            /> */}
            </>
          ) : (
            <div>No Data Found</div>
          )}
        </>
      )}
    </>
  );
};

export default VideoRes;
