import React from "react";
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import "./App.css";
import "./index.css";
import "./Images/bg.svg";
import { Toaster } from "react-hot-toast";
import routes from "./routes/routes";
import { MainLayout, AdminLayout, LoginLayout } from "./layout";

function App() {
  return (
    <HashRouter>
      <div className="App mobile:overflow-x-hidden">
        <Toaster />
        <Routes>
          {/* Default Route Redirect */}
          <Route path="/*" element={<Navigate to="/home" />} />

          {/* Public Routes */}
          {routes.public.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<MainLayout>{element}</MainLayout>}
            />
          ))}

          {/* Login-Based Routes */}
          {routes.login.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<LoginLayout>{element}</LoginLayout>}
            />
          ))}

          {/* Default General Route */}
          {routes.general.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}

          {/* Admin-Based Routes */}
          {routes.admin.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<AdminLayout>{element}</AdminLayout>}
            />
          ))}
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
