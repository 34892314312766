import { Link } from "react-router-dom";

const StatsCard = ({ url, content }) => {
  return (
    <Link to={url}>
      <div className="bg-white mobile:w-full w-[170px] rounded-[15px] h-[150px] flex flex-col text-center justify-center items-center">
        {content}
      </div>
    </Link>
  );
};

export default StatsCard;
