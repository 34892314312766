import React, { useEffect, useState } from "react";
import logo from "../Images/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateTestimonial from "../pages/CreateTestimonial";
import Button from "../pages/Button";
import { useDispatch } from "react-redux";
import { logoutuser } from "../store/actions/auth.action";

const LoginHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage(location.pathname);
  }, [location]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  const handleLogout = () => {
    dispatch(
      logoutuser((response) => {
        if (response.status === 200) {
          localStorage?.clear();
          navigate("/login");
        }
      })
    );
  };
  const profileInfo = JSON.parse(localStorage?.getItem("userInfo"));
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0] : "";
    const lastInitial = lastName ? lastName[0] : "";
    return `${firstInitial}${lastInitial}`;
  };

  const initials = getInitials(profileInfo?.firstName, profileInfo?.lastName);
  const [initialPopup, setInitialPopup] = useState(false);
  return (
    <>
      {initialPopup && <CreateTestimonial setInitialPopup={setInitialPopup} />}
      <div className="w-full fixed mt-5 bg-white z-50 mobile:overflow-x-hidden mobile:mt-0">
        <nav className="bg-white border-gray-200 dark:bg-gray-900 ">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link to={"/Home"} onClick={closeMenu}>
              <div
                href=""
                className="flex items-center space-x-3 rtl:space-x-reverse"
              >
                <img src={logo} alt="" />
              </div>
            </Link>
            <button
              onClick={toggleMenu}
              data-collapse-toggle="navbar-default"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center laptop:hidden "
              aria-controls="navbar-default"
              aria-expanded={menuOpen ? "true" : "false"}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.6 14.4H21.6C22.2365 14.4 22.847 14.1471 23.2971 13.6971C23.7471 13.247 24 12.6365 24 12C24 11.3635 23.7471 10.753 23.2971 10.3029C22.847 9.85286 22.2365 9.6 21.6 9.6H9.6C8.96348 9.6 8.35303 9.85286 7.90294 10.3029C7.45286 10.753 7.2 11.3635 7.2 12C7.2 12.6365 7.45286 13.247 7.90294 13.6971C8.35303 14.1471 8.96348 14.4 9.6 14.4ZM0 2.4C0 1.76348 0.252857 1.15303 0.702944 0.702944C1.15303 0.252856 1.76348 0 2.4 0H21.6C22.2365 0 22.847 0.252856 23.2971 0.702944C23.7471 1.15303 24 1.76348 24 2.4C24 3.03652 23.7471 3.64697 23.2971 4.09706C22.847 4.54714 22.2365 4.8 21.6 4.8H2.4C1.76348 4.8 1.15303 4.54714 0.702944 4.09706C0.252857 3.64697 0 3.03652 0 2.4ZM14.4 21.6C14.4 20.9635 14.6529 20.353 15.1029 19.9029C15.553 19.4529 16.1635 19.2 16.8 19.2H21.6C22.2365 19.2 22.847 19.4529 23.2971 19.9029C23.7471 20.353 24 20.9635 24 21.6C24 22.2365 23.7471 22.847 23.2971 23.2971C22.847 23.7471 22.2365 24 21.6 24H16.8C16.1635 24 15.553 23.7471 15.1029 23.2971C14.6529 22.847 14.4 22.2365 14.4 21.6Z"
                  fill="#214284"
                />
              </svg>
            </button>
            <div
              className={`${
                menuOpen
                  ? "block h-screen w-full justify-start items-start"
                  : "hidden"
              } w-full md:block md:w-auto`}
              id="navbar-default"
            >
              <ul className="font-medium flex flex-col md:flex-row items-center mobile:items-start mobile:text-start p-4 md:p-0 mt-4 border mobile:h-screen border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <Link
                  to={"/aboutus"}
                  className="mobile:border-b mobile:w-full mobile:border-b-secondary"
                  onClick={closeMenu}
                >
                  <li>
                    <p
                      className={`block py-2 px-2  text-[#214284] font-poppins text-[19px] font-normal" aria-current="page"  ${
                        activePage === "/aboutus"
                          ? "bg-tertiary px-5 text-primary rounded-3xl border-b-secondary border-b-2 "
                          : ""
                      }`}
                    >
                      About Us
                    </p>
                  </li>
                </Link>
                <Link
                  to={"/resources"}
                  className="mobile:border-b mobile:w-full mobile:border-b-secondary"
                  onClick={closeMenu}
                >
                  <li>
                    <p
                      className={`block py-2 px-2  text-[#214284] font-poppins text-[19px] font-normal" aria-current="page"  ${
                        activePage === "/resources"
                          ? "bg-tertiary text-primary px-5 rounded-3xl border-b-secondary border-b-2 "
                          : ""
                      }`}
                    >
                      Resources
                    </p>
                  </li>
                </Link>
                <Link
                  to={"/pricing"}
                  className="mobile:border-b mobile:w-full mobile:border-b-secondary"
                  onClick={closeMenu}
                >
                  <li>
                    <p
                      className={`block py-2 px-2  text-[#214284] font-poppins text-[19px] font-normal" aria-current="page"  ${
                        activePage === "/pricing"
                          ? "bg-tertiary px-5 text-primary rounded-3xl border-b-secondary border-b-2 "
                          : ""
                      }`}
                    >
                      Pricing
                    </p>
                  </li>
                </Link>
                <Link
                  to={"/blogs"}
                  className="mobile:border-b mobile:w-full mobile:border-b-secondary"
                  onClick={closeMenu}
                >
                  <li>
                    <p
                      className={`block py-2  text-[#214284] px-2 font-poppins text-[19px] font-normal" aria-current="page"  ${
                        activePage === "/blogs"
                          ? "bg-tertiary px-5 text-primary rounded-3xl border-b-secondary border-b-2 "
                          : ""
                      }`}
                    >
                      Blogs
                    </p>
                  </li>
                </Link>
                <Link
                  to={"/contactus"}
                  className="mobile:border-b mobile:w-full mobile:border-b-secondary"
                  onClick={closeMenu}
                >
                  <li>
                    <p
                      className={`block py-2  text-[#214284] px-2 font-poppins text-[19px] font-normal" aria-current="page"  ${
                        activePage === "/blogs"
                          ? "bg-tertiary px-5 text-primary rounded-3xl border-b-secondary border-b-2 "
                          : ""
                      }`}
                    >
                      Contact
                    </p>
                  </li>
                </Link>
                <li onClick={toggleDropdown}>
                  <div className="profileGrad mobile:hidden rounded-[50px]  w-[50px] h-[50px] flex justify-center items-center text-primary  font-semibold font-poppins text-xl cursor-pointer">
                    {initials}
                  </div>
                  <div className=" laptop:hidden rounded-[50px] mt-10 w-[50px] h-[50px] flex justify-center items-center text-primary  font-semibold font-poppins text-xl cursor-pointer">
                    <div className="w-full md:w-auto ">
                      <Button
                        label="View Profile"
                        type="submit"
                        variant="outline"
                      />
                    </div>
                  </div>
                  {dropdownOpen && (
                    <div
                      className="absolute right-4 font-poppins  mt-2 p-3 dropdownShadow text-center w-48 bg-white rounded-[20px] overflow-hidden shadow-xl z-10"
                      onMouseLeave={() => {
                        setDropdownOpen(false);
                      }}
                    >
                      <div className="py-1">
                        {profileInfo?.kycVerified === true && (
                          <Link to="/profile" onClick={closeMenu}>
                            <p className="block  border-b border-b-secondary px-4 py-2 text-base text-primary ">
                              View Profile
                            </p>
                          </Link>
                        )}
                        {profileInfo?.kycVerified === true && (
                          <Link to="/insiescreen" onClick={closeMenu}>
                            <p className="block  border-b border-b-secondary px-4 py-2 text-base text-primary ">
                              View Events
                            </p>
                          </Link>
                        )}
                        {/* <Link to="/createtestimonial"> */}
                        {profileInfo?.kycVerified === true && (
                          <p
                            className="block  border-b cursor-pointer border-b-secondary px-4 py-2 text-base text-primary "
                            onClick={() => setInitialPopup(true)}
                          >
                            Add Testimonial
                          </p>
                        )}
                        {/* </Link> */}
                        <p
                          className="block px-4 py-2 text-base text-primary cursor-pointer"
                          onClick={handleLogout}
                        >
                          Logout
                        </p>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default LoginHeader;
