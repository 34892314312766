import React from "react";

const HeroSection = () => {
  return (
    <div className="relative flex flex-wrap items-center justify-between mb-28 mobile:mb-14 pb-12">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={require("./hero_banner_black.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="flex flex-col items-center justify-center z-10 mt-28 mobile:w-full mobile:p-5 gap-12 mobile:gap-6">
        <p className="mt-20 mobile:mt-5 text-center text-h1 mobile:text-3xl font-lora mobile:leading-10 font-[700] text-secondary">
          Transform your trading journey with TFS!!
          <span className="flex justify-center">
            <hr className="w-[15%] mt-5 border-[2px] border-[#FFBB6B] mobile:hidden" />
          </span>
        </p>
        <div className="flex flex-col md:px-10 gap-5">
          {[
            `Embark on a holistic trading journey that covers everything you need
            from solid technical knowledge that is world class and psychological
            wisdom to actionable insights.`,
            `Join thousands of enthusiastic learners under the guidance of
            Nishant Arora who's the best trader, teacher, a coach, and a mentor.`,
            `With over a decade of experience and having mentored more than 5000
            students and a thriving community of 20,000 traders on Facebook,
            Nishant Arora has been a guiding light for those seeking financial
            mastery and personal growth.`,
          ].map((item, index) => (
            <p
              key={index}
              className="text-center font-poppins mobile:w-full mobile:text-justify font-normal text-white"
            >
              {item}
            </p>
          ))}
        </div>
        <p className="text-center text-h3 mobile:text-3xl font-lora mobile:leading-10 font-[700] text-secondary">
          Get ready to transform your life and take control of your financial
          future!!
        </p>
        <div className="flex flex-wrap items-center gap-5 mt-10 mobile:mt-7 mobile:w-full">
          <a
            href="https://www.facebook.com/groups/welcometotfs/"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile:w-full"
          >
            <p className="py-4 mobile:w-full text-primary flex justify-center items-center font-[600] text-[20px] font-poppins getInTouch px-10 ">
              Join Our Community
            </p>
          </a>
          <a
            href="https://www.youtube.com/embed/kclDCilaMas?si=dvUtqNAdJc9umzsO"
            target="_blank"
            rel="noopener noreferrer"
            className="py-3 mobile:w-full text-white flex justify-center items-center font-[600] text-[20px] font-poppins px-10 border-[3px] border-white rounded-[35px]"
          >
            Our Podcasts
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
