export const GET_ALL_GALARY = "GET_ALL_GALARY";
export const GET_ALL_GALARY_SUCCESS = "GET_ALL_GALARY_SUCCESS";
export const GET_ALL_GALARY_FAILURE = "GET_ALL_GALARY_FAILURE";

export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILURE = "GET_ALL_BLOGS_FAILURE";

export const GET_BLOG_BY_ID = "GET_BLOG_BY_ID";
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_FAILURE = "GET_BLOG_BY_ID_FAILURE";

export const SUBSCRIBE_FORM = "SUBSCRIBE_FORM";
export const SUBSCRIBE_FORM_SUCCESS = "SUBSCRIBE_FORM_SUCCESS";
export const SUBSCRIBE_FORM_FAILURE = "SUBSCRIBE_FORM_FAILURE";

export const CHECK_USER = "CHECK_USER";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const CHECK_USER_FAILURE = "CHECK_USER_FAILURE";

export const GET_PROFILE_INFO = "GET_PROFILE_INFO";
export const GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS";
export const GET_PROFILE_INFO_FAILURE = "GET_PROFILE_INFO_FAILURE";

export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS";
export const GET_ALL_EVENTS_FAILURE = "GET_ALL_EVENTS_FAILURE";

export const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_FAILURE = "GET_EVENT_BY_ID_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const SUBMIT_QUERY = "SUBMIT_QUERY";
export const SUBMIT_QUERY_SUCCESS = "SUBMIT_QUERY_SUCCESS";
export const SUBMIT_QUERY_FAILURE = "SUBMIT_QUERY_FAILURE";

export const PROFILE_CHANGE_REQ = "PROFILE_CHANGE_REQ";
export const PROFILE_CHANGE_REQ_SUCCESS = "PROFILE_CHANGE_REQ_SUCCESS";
export const PROFILE_CHANGE_REQ_FAILURE = "PROFILE_CHANGE_REQ_FAILURE";

export const PROFILE_CHANGE_LIST = "PROFILE_CHANGE_LIST";
export const PROFILE_CHANGE_LIST_SUCCESS = "PROFILE_CHANGE_LIST_SUCCESS";
export const PROFILE_CHANGE_LIST_FAILURE = "PROFILE_CHANGE_LIST_FAILURE";

export const GET_BATCH_LIST = "GET_BATCH_LIST";
export const GET_BATCH_LIST_SUCCESS = "GET_BATCH_LIST_SUCCESS";
export const GET_BATCH_LIST_FAILURE = "GET_BATCH_LIST_FAILURE";

export const BATCH_CHANGE_REQ = "BATCH_CHANGE_REQ";
export const BATCH_CHANGE_REQ_SUCCESS = "BATCH_CHANGE_REQ_SUCCESS";
export const BATCH_CHANGE_REQ_FAILURE = "BATCH_CHANGE_REQ_FAILURE";

export const BATCH_CHANGE_REQ_LIST = "BATCH_CHANGE_REQ_LIST";
export const BATCH_CHANGE_REQ_LIST_SUCCESS = "BATCH_CHANGE_REQ_LIST_SUCCESS";
export const BATCH_CHANGE_REQ_LIST_FAILURE = "BATCH_CHANGE_REQ_LIST_FAILURE";

export const BATCH_CANCEL = "BATCH_CANCEL";
export const BATCH_CANCEL_SUCCESS = "BATCH_CANCEL_SUCCESS";
export const BATCH_CANCEL_FAILURE = "BATCH_CANCEL_FAILURE";

export const REGISTER_TO_EVENT = "REGISTER_TO_EVENT";
export const REGISTER_TO_EVENT_SUCCESS = "REGISTER_TO_EVENT_SUCCESS";
export const REGISTER_TO_EVENT_FAILURE = "REGISTER_TO_EVENT_FAILURE";

export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCCESS";
export const ADD_TESTIMONIAL_FAILURE = "ADD_TESTIMONIAL_FAILURE";

export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS";
export const GET_ALL_PLANS_FAILURE = "GET_ALL_PLANS_FAILURE";

export const GET_ALL_TESTIMONIALS = "GET_ALL_TESTIMONIALS";
export const GET_ALL_TESTIMONIALS_SUCCESS = "GET_ALL_TESTIMONIALS_SUCCESS";
export const GET_ALL_TESTIMONIALS_FAILURE = "GET_ALL_TESTIMONIALS_FAILURE";

export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAILURE = "UPDATE_STATUS_FAILURE";

export const GET_SIGN_URL = "GET_SIGN_URL";
export const GET_SIGN_URL_SUCCESS = "GET_SIGN_URL_SUCCESS";
export const GET_SIGN_URL_FAILURE = "GET_SIGN_URL_FAILURE";

//useronboarding

export const ENTER_BASIC_DETAILS = "ENTER_BASIC_DETAILS";
export const ENTER_BASIC_DETAILS_SUCCESS = "ENTER_BASIC_DETAILS_SUCCESS";
export const ENTER_BASIC_DETAILS_FAILURE = "ENTER_BASIC_DETAILS_FAILURE";

export const VALDATE_REGISTRATION = "VALDATE_REGISTRATION";
export const VALDATE_REGISTRATION_SUCCESS = "VALDATE_REGISTRATION_SUCCESS";
export const VALDATE_REGISTRATION_FAILURE = "VALDATE_REGISTRATION_FAILURE";

export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const INITIATE_PAYMENT_SUCCESS = "INITIATE_PAYMENT_SUCCESS";
export const INITIATE_PAYMENT_FAILURE = "INITIATE_PAYMENT_FAILURE";

export const CHECK_AND_UPDATE = "CHECK_AND_UPDATE";
export const CHECK_AND_UPDATE_SUCCESS = "CHECK_AND_UPDATE_SUCCESS";
export const CHECK_AND_UPDATE_FAILURE = "CHECK_AND_UPDATE_FAILURE";

export const SUBMIT_KYC_INFO = "SUBMIT_KYC_INFO";
export const SUBMIT_KYC_INFO_SUCCESS = "SUBMIT_KYC_INFO_SUCCESS";
export const SUBMIT_KYC_INFO_FAILURE = "SUBMIT_KYC_INFO_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";