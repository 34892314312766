import React, { useEffect, useRef, useState } from "react";
import slideImg from "../Images/slideimg.png";
import roundImg from "../Images/bigRound.svg";
import underLine from "../Images/underline.svg";
import aboutimg from "../Images/abutimg.svg";
import title from "../Images/nishant.svg";
import fb from "../Images/facebook.png";
import blueUnion from "../Images/blueunion.svg";
import ig from "../Images/ig.png";
import telegram from "../Images/telegram.png";
import videoImg from "../Images/bigvideo2.JPG";
import playImg from "../Images/playbtn.svg";
import prevBtn from "../Images/prev.svg";
import nextBtn from "../Images/next.svg";
import liImg from "../Images/li.svg";
import aboutImg from "../Images/whyus.png";
import Testimonials from "../components/HomePage/Testimonials";
import { Link } from "react-router-dom";
import { getAllPlans } from "../store/actions/website.action";
import { useDispatch, useSelector } from "react-redux";
import Accordian from "../components/Accordian";
import Download from "../components/Download";
import DownloadBrchure from "../components/Download";
import a1 from "../Images/rotate/1.png";
import a2 from "../Images/rotate/2.png";
import a3 from "../Images/rotate/3.png";
import a4 from "../Images/rotate/4.png";
import a5 from "../Images/rotate/5.png";
import a6 from "../Images/rotate/6.png";
import a7 from "../Images/rotate/7.png";
import a8 from "../Images/rotate/8.png";
import a9 from "../Images/rotate/9.png";
import a10 from "../Images/rotate/10.png";
import ServiceCard from "../components/ServiceCard";
import { services, slides } from "../data/appData";

const MilestoneCircle = ({ isActive, year, onClick }) => (
  <div
    onClick={onClick}
    className={`w-24 h-24 mobile:w-16 mobile:h-16 flex items-center justify-center 
          rounded-full font-bold text-center cursor-pointer shrink-0
          ${
            isActive
              ? "bg-secondary text-primary ring-primary ring-2 ring-offset-8 mobile:ring-offset-4"
              : "bg-tertiary text-secondary"
          }`}
  >
    {year}
  </div>
);

const Milestone = ({ isActive, year, onClick, hideLine }) => (
  <div className="flex items-center w-full gap-4">
    {/* Circle stays fixed size */}
    <MilestoneCircle isActive={isActive} year={year} onClick={onClick} />

    {/* Line takes up available space but stays visible */}
    {!hideLine && (
      <div className="flex-1 min-w-[20px] h-1 bg-gray-200 pink:bg-gray-700"></div>
    )}
  </div>
);

const events = [
  {
    year: 2017,
    description: "Event 1",
    content:
      "TFS Whatsapp/Facebook created with just 6 members which grew up to be  thriving community of 20K members",
  },
  {
    year: 2019,
    description: "Event 2",
    content:
      "TIC Started with a massive response of 500+ enrollment. Currently more than 10 batches have enrolled in different TIC edition(s).",
  },
  {
    year: 2021,
    description: "Event 3",
    content:
      "Mentorship program started and became so popular that weekend slots for next few months were booked.",
  },
  {
    year: 2023,
    description: "Event 5",
    content:
      " First edition of ‘Pratyaksh : A TIC meet and greet with Nishant Arora’ was launched. Became a flagship annual event.",
  },
];

const AboutUs = () => {
  const [active, setActive] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const scrollRef = useRef(null);
  // const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef(null);
  const imageArray = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10];

  // Handle "Read More" click
  const handleReadMore = () => {
    setReadMore(true);
    setTimeout(() => {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100); // Delay to ensure content expands first
  };

  // Handle mouse drag start
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.pageY - scrollRef.current.offsetTop);
    setScrollTop(scrollRef.current.scrollTop);
  };

  // Handle mouse drag stop
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Handle mouse movement for scrolling
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const y = e.pageY - scrollRef.current.offsetTop;
    const walk = (y - startY) * 1.5; // Adjust scroll speed
    scrollRef.current.scrollTop = scrollTop - walk;
  };

  const isFirstRender = useRef(true);
  const [readmore, setreadmore] = useState(false);
  useEffect(() => {
    if (isFirstRender.current && events.length > 0) {
      setSelectedEvent(events[0]);
      isFirstRender.current = false;
    }
  }, [events]);
  const [activeTab, setActiveTab] = useState("monthly");
  const defaultSelectedEvent =
    events.length > 0
      ? events[0]
      : { year: 2000, description: "Event 1", content: "Content for Event 1" };
  const [selectedEvent, setSelectedEvent] = useState(defaultSelectedEvent);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modal, setmodal] = useState(false);
  const dispatch = useDispatch();
  const { planList } = useSelector((state) => state?.web);
  console.log("planList", planList);
  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch, activeTab]);

  const handleItemClick = (index) => {
    setSelectedEvent(events[index]);
    setCurrentSlide(index);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => {
      const newSlide = (prevSlide - 1 + slides.length) % slides.length;
      setSelectedEvent(events[newSlide]);
      return newSlide;
    });
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => {
      const newSlide = (prevSlide + 1) % slides.length;
      setSelectedEvent(events[newSlide]);
      return newSlide;
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment rotation by a certain degree (adjust the value as needed)
      setRotation((rotation) => rotation - 10);
    }, 2000); // Adjust the interval (in milliseconds) as needed

    return () => clearInterval(interval);
  }, []);
  const downloadDemoFile = () => {
    // Create a Blob with dummy content
    const blob = new Blob(["This is a demo file content."], {
      type: "text/plain",
    });
    const url = URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "demo.txt"; // File name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Release the Blob URL
    URL.revokeObjectURL(url);
  };
  console.log(modal, "modallll");
  return (
    <>
      {modal === true && <DownloadBrchure setmodal={setmodal} />}
      <div className=" h-full  bg-contain -mt-5 mb-32 bg-[url('https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/background.png')] bg-no-repeat">
        <div className="flex flex-col mb-20 mobile:mb-10 tablet:mb-10 justify-center items-center ">
          <p className="text-secondary text-h5 font-bold tablet:mt-44	mt-36">
            About TFS
          </p>
          <p className="text-primary font-bold mt-7 text-center text-h2 mobile:text-h4 tablet:text-h4 font-lora">
            Empowering Traders, Transforming Lives
          </p>
          <hr className="w-[5%]" />
          <p className="text-body font-[400] w-[72%] mobile:w-full tablet:w-full text-center font-poppins text-primary mt-10 mb-30  ">
            Train For Success (TFS) and The Inner Circle (TIC) stand as beacons
            of excellence in the realm of trading education and personal
            development. Led by visionary Nishant Arora, our platforms have
            garnered the trust of over 20,000 members, setting the standard for
            sincerity and efficacy in trading education.
          </p>
        </div>
        {/* <div class="container mobile:hidden">
          <div class="mt-96 mb-72">
            <div
              className="circular-carousel-slider"
              style={{
                transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
              }}
            >
              {imageArray.map((image, index) => (
                <div
                  className="slide-item"
                  key={index}
                  style={{ transform: `rotate(${index * 9}deg)` }}
                >
                  <img
                    src={image}
                    alt={`Slide ${index}`}
                    onError={(e) =>
                      console.log("Image failed to load:", e.target.src)
                    }
                  />
                </div>
              ))}
            </div>
            <div class="flex justify-center -mt-52">
              <img
                src="https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/sholliute.png"
                alt=""
                class=" "
              />
            </div>
          </div>
        </div> */}
        <div className="container w-full overflow-hidden relative mb-20 mobile:hidden">
          <div className="flex gap-10 animate-scroll">
            {imageArray.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-80 h-80 object-cover"
                onError={(e) =>
                  console.log("Image failed to load:", e.target.src)
                }
              />
            ))}
          </div>
          <div class="flex justify-center mt-20">
            <img src="https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/sholliute.png" />
          </div>
        </div>
        <div className="bg-primary w-full overflow-hidden relative mb-2">
          <div className="flex gap-20 items-center justify-center whitespace-nowrap animate-scroll">
            {Array(8)
              .fill("From Mentors Table")
              .map((item) => (
                <div>
                  <p className="text-white text-[36px]">{item}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="relative w-full h-full">
          <div className="trapz2">
            <div className="w-full px-32 mobile:px-3 tablet:px-3 tablet:flex-col mobile:flex-col-reverse flex justify-center ">
              <div className="w-[50%] tablet:flex tablet:justify-center mt-20 mobile:w-full tablet:w-full">
                <img
                  src="https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/Group+1321314670.png"
                  alt=""
                />
              </div>
              <div
                ref={containerRef}
                className="w-[45%] h-auto mobile:mt-8 tablet:flex tablet:justify-center tablet:flex-col tablet:px-10 tablet:mt-8 tablet:w-full mt-20 mobile:w-full"
              >
                <img src={title} alt="" className="w-[60%]" />
                <hr className="w-[15%] mt-7 border-[2px] border-tertiary" />
                <br />
                <p
                  className="text-white font-poppins text-justify text-[24px] tablet:text-[18px] mobile:text-[18px]"
                  style={{ wordSpacing: "10px" }}
                >
                  Greetings, my fellow learners and traders,
                </p>
                <p className="text-white font-poppins text-justify tablet:text-[18px] text-[24px] mobile:text-[18px] mt-3 tracking-tight">
                  In the vast world of trading education and personal
                  development, I've embarked on a journey to redefine how we
                  approach success. My name is Nishant Arora, and I've had the
                  privilege of spearheading two transformative platforms: Train
                  For Success (TFS) and The Inner Circle (TIC).
                </p>

                {!readMore && (
                  <p
                    className="text-secondary text-h5 font-poppins underline mt-5 tablet:text-[18px] mobile:text-[18px] cursor-pointer"
                    onClick={handleReadMore}
                  >
                    Read More
                  </p>
                )}

                {readMore && (
                  <div className="relative h-auto w-full rounded-lg mt-3">
                    <p className="text-white font-poppins text-justify tablet:text-[18px] text-[24px] mobile:text-[18px] tracking-tight">
                      We strive to cultivate a holistic success academy that
                      places trading education at its core. Through our vibrant
                      Facebook group, we've fostered a thriving community of
                      over 20,000 members, each on their own unique trading
                      journey. I've dedicated myself to guiding traders towards
                      financial independence.
                    </p>
                    <p className="text-white font-poppins text-justify tablet:text-[18px] text-[24px] mobile:text-[18px] tracking-tight mt-3">
                      Our approach combines cutting-edge strategies, market
                      insights, and mindset training to ensure long-term
                      success. Whether you're a beginner or an experienced
                      trader, our platform offers tools and mentorship to help
                      you achieve your financial goals.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col px-28 mobile:px-5 tablet:px-10 mt-10">
          <p className="text-secondary font-bold mt-7 mobile:text-h3 tablet:text-h3 text-h2">
            Empowering Traders, Transforming Lives
          </p>
          <p className="text-primary font-bold text-h2 mobile:text-h3 tablet:text-h3">
            Our Mission and Vision :
          </p>
          <div className="flex flex-col gap-10 mobile:gap-6 mb-10">
            <p className="text-body font-[400]  font-poppins text-primary">
              Experience the pinnacle of learning and development with TFS and
              TIC - where personal transformation meets best in class trading
              education. Note : Something about the trading aspect of TFS/TIC
              With a focus on individual transformation so as to utilise the
              best in class market learnings, Our programs are designed to
              empower you to be the best version of yourself and achieve your
              goals in the fiercely competitive world of trading at your own
              pace. Join us today and embark on a journey of lasting success and
              fulfillment. Check once
            </p>
            <p className="text-body font-[400]  font-poppins text-primary">
              With a focus on individual transformation so as to utilise the
              best in class market learnings, Our programs are designed to
              empower you to be the best version of yourself and achieve your
              goals in the fiercely competitive world of trading at your own
              pace.
            </p>
            <p className="text-body font-[400]  font-poppins text-primary">
              Join us today and embark on a journey of lasting success and
              fulfillment.
            </p>
          </div>
          <div>
            <div className="grid grid-cols-3 mobile:grid-cols-1  tablet:grid-cols-2 tablet:gap-5 mobile:gap-5 gap-16">
              {services.map((item) => (
                <ServiceCard underLine={underLine} {...item} />
              ))}
            </div>
          </div>
        </div>
        {/* <div className="w-full flex px-28 bg-tertiary mobile:px-3 mobile:py-7 tablet:px-10 tablet:py-7 py-20 mt-28">
            <div className="w-full flex mobile:flex-col tablet:flex-col gap-10">
              <div className="w-[50%] mobile:w-full tablet:w-full">
                <p className="text-h5 font-[700] text-secondary mb-3 mobile:text-h3 tablet:text-h3">
                  Why choose us
                </p>
                <p className="text-h2 font-[700] text-primary mobile:text-h3 tablet:text-h3">
                  {" "}
                  Elevate Your Trading <br />
                  Journey with TFS and TIC
                </p>
                <hr className="w-[15%] mt-10 mobile:mt-4 tablet:mt-4 tablet:mb-3 mobile:mb-3 mb-5 border-[3px] border-[#FFBB6B] " />
                <p className="text-body text-primary font-poppins tracking-wide text-justify tablet:w-full w-[85%] mobile:w-full mt-10">
                  Choose Train For Success (TFS) and The Inner Circle (TIC) for
                  unparalleled trading education and personal development. Led
                  by visionary Nishant Arora, TFS boasts a Facebook group with
                  20,000+ members, renowned for its sincere approach to guiding
                  traders on their journey. TIC, an exclusive offshoot, offers a
                  premium experience with real-time mentorship and a thriving
                  community of over 2,000 students. With TFS and TIC, success
                  isn't just taught; it's lived and shared, making us the
                  ultimate choice for those seeking to thrive in the world of
                  trading and beyond.
                </p>
                <div className="flex flex-wrap items-center gap-5 mt-10">
                  <Link
                    to={"/contactus"}
                    className=" py-4 mobile:w-full   text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins px-10 border-[3px] border-primary rounded-[35px]"
                  >
                    Contact Us
                  </Link>
                  <p
                    className="py-5 mobile:w-full  text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-10"
                    // onClick={downloadDemoFile}
                    onClick={() => setmodal(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Download Brochure
                  </p>
                </div>
              </div>
              <div className="w-[50%] h-full mobile:w-full tablet:w-full">
                <div className="w-full flex  mobile:flex-col ">
                  <div className="w-[50%] mobile:w-full mobile:h-full h-[90%]">
                    <div className="bg-[#ffcf97] border-2 w-[80%] mobile:w-full h-[50%] py-9 border-white lightboxShadow rounded-[30px]  p-5 ">
                      <p className="text-[70px] text-primary font-bold text-center ">
                        20M+
                      </p>
                      <p className="text-3xl text-center font-poppins text-primary">
                        Growing Community
                      </p>
                    </div>
                    <div className="bg-[#bdcbe6] border-2 py-9 w-[80%] mobile:w-full  mt-10 h-[50%]  border-white lightboxShadow rounded-[30px]  p-5 ">
                      <p className="text-[70px] text-white font-bold text-center ">
                        200K+
                      </p>
                      <p className="text-3xl text-center font-poppins text-white">
                        Feedbacks Received
                      </p>
                    </div>
                  </div>
                  <div className="w-[50%] -ml-10 mobile:ml-0 mobile:w-full mobile:h-full mobile:mt-10 h-[90%] mt-16">
                    <div className="bg-[#bdcbe6] border-2 py-9 w-[80%] mobile:w-full  h-[50%] border-white lightboxShadow rounded-[30px]  p-5 ">
                      <p className="text-[70px] text-white font-bold text-center ">
                        2K+
                      </p>
                      <p className="text-3xl text-center font-poppins text-white">
                        TIC <br />
                        Students
                      </p>
                    </div>
                    <div className="bg-[#ffcf97] border-2 mt-10 py-9 w-[80%] mobile:w-full  h-[60%] border-white lightboxShadow rounded-[30px]  p-5 ">
                      <p className="text-[70px] text-primary font-bold text-center ">
                        24/7
                      </p>
                      <p className="text-3xl text-center font-poppins text-primary mt-5">
                        Supports
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        <div className="w-full relative">
          <div className="w-full relative mt-10">
            <div className="w-full flex flex-col  justify-center items-center ">
              <p className="text-secondary font-bold  text-center text-h2 mt-20  mobile:mb-10 mobile:mt-10 tablet:mt-10 tablet:text-h4 mobile:text-h4">
                To Know Us More
              </p>
              <p className="mobile:mt-10 text-primary font-bold mt-3 text-center text-h2 mb-60 mobile:text-h4 mobile:mb-5">
                Watch this video
              </p>
              <br />
              <br />
              <br />
              <br />
              <div className="rounded-[32px]  mobile:w-full w-[80%] h-[60%] mobile:mt-0 mobile:rounded-none  mt-20 flex justify-center items-center">
                <div className="absolute mobile:w-full">
                  <Link to={"https://youtu.be/kf_GQqXx2No?si=PN6m8NKYl3jkKL_5"}>
                    <img src={videoImg} alt="" className="w-full h-auto" />
                  </Link>
                  <img
                    src={playImg}
                    alt=""
                    className="absolute top-1/2 mobile:w-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full bg-primary ">
            <div className="max-w-screen-xl  py-16 mobile:px-4 mobile:py-5 px-10 flex h-full flex-wrap items-center justify-between mx-auto">
              <div className="w-full flex mobile:flex-col gap-14 h-full items-center mt-[18rem] mobile:mt-28">
                <Accordian />
                {/* <div className="w-[50%] mobile:w-full flex flex-col justify-center items-center">
                    <div className="flex justify-center rounded-[36px] bg-[#FFF] tablet:w-[61%] w-[41%] mobile:w-[60%] mt-8 p-1">
                      <button
                        className={`px-4 py-2 font-[600] font-poppins text-primary rounded-[36px] ${
                          activeTab === "monthly"
                            ? "bg-secondary "
                            : "bg-[#FFF] "
                        }`}
                        onClick={() => handleTabClick("monthly")}
                      >
                        Half Yearly
                      </button>
                      <button
                        className={`px-4 py-2 font-poppins font-[600] text-primary rounded-[36px] ${
                          activeTab === "yearly"
                            ? "bg-secondary text-[#FFF]"
                            : "bg-[#FFF]"
                        }`}
                        onClick={() => handleTabClick("yearly")}
                      >
                        Yearly
                      </button>
                    </div>
                    <div className="mt-10">
                      {activeTab === "monthly" && (
                        <div className="glassbg mobile:rounded-[35px] flex flex-col items-center justify-center p-16 w-[100%]  mobile:p-9">
                          <p className="text-h6 text-white font-poppins font-[600]">
                            Basic
                          </p>
                          <p className="text-h1 font-[600] mobile:text-h2 text-white">
                            INR {planList?.[0]?.fees}
                          </p>
                          <hr className="border w-full text-white h-[0.5px] opacity-5 mt-10 mb-10" />
                          <ul className="text-white font-poppins text-[16px] flex flex-col gap-5">
                            <li className="flex items-center space-x-3 rtl:space-x-reverse">
                              <img src={liImg} alt="" />{" "}
                              <span>Inclusive of GST</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse">
                              <img src={liImg} alt="" />{" "}
                              <span> Free Platform Access</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse">
                              <img src={liImg} alt="" />{" "}
                              <span> 24/7 Customer Support</span>
                            </li>
                          </ul>
                          <Link to={"/getstarted"}>
                            {" "}
                            <p className=" py-4 mt-10 text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-5">
                              Get Started Today
                            </p>
                          </Link>
                        </div>
                      )}
                      {activeTab === "yearly" && (
                        <div className="glassbg mobile:rounded-[35px] flex flex-col items-center justify-center p-16 w-[100%]  mobile:p-9">
                          <p className="text-h6 text-white font-poppins font-[600]">
                            Yearly
                          </p>
                          <p className="text-h1 font-[600] text-white  mobile:text-h2">
                            INR {planList?.[1]?.fees}
                          </p>
                          <hr className="border w-full text-white h-[0.5px] opacity-5 mt-10 mb-10" />
                          <ul className="text-white font-poppins text-[16px] flex flex-col gap-5">
                            <li className="flex items-center space-x-3 rtl:space-x-reverse">
                              <img src={liImg} alt="" />{" "}
                              <span>Inclusive of GST</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse">
                              <img src={liImg} alt="" />{" "}
                              <span> Free Platform Access</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse">
                              <img src={liImg} alt="" />{" "}
                              <span> 24/7 Customer Support</span>
                            </li>
                          </ul>
                          <Link to="/getstarted">
                            <p className=" py-4 mt-10 text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-5">
                              Get Started Today
                            </p>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full  flex px-28 mobile:p-5 mobile:pl-0 pl-52 flex-col mt-20">
          <p className="text-h2 text-primary  mobile:p-5  mobile:text-h4 font-bold">
            Journey Milestones:
            <span className="text-secondary"> Our Timeline</span>
          </p>
        </div> */}

        <div className="w-full flex flex-col items-center gap-10 mt-20 mobile:mt-5 mobile:px-5">
          <p className="text-h2 text-primary mobile:text-h4 font-bold">
            Journey Milestones:
            <span className="text-secondary"> Our Timeline</span>
          </p>
          <div className="flex justify-center gap-4 mobile:gap-2 max-w-[90%] mx-auto px-5 mobile:px-2">
            {events.map((event, index) => (
              <Milestone
                key={index}
                onClick={() => handleItemClick(index)}
                isActive={selectedEvent.year === event.year}
                year={event.year}
                hideLine={index === events.length - 1}
              />
            ))}
          </div>

          <p className="text-primary font-bold">{selectedEvent.content}</p>
        </div>

        {/* <div className="mt-8 mobile:p-3">
            {selectedEvent && (
              <div className="w-full flex mobile:flex-col mobile:pl-0 mobile:pr-0   mobile:mt-5   pl-36 pr-36 mt-20">
                <div className="w-[50%] mobile:w-full">
                  <p className="text-h5 mobile:text-h6 font-[700] text-secondary mb-3">
                    {slides[currentSlide]?.heading}
                  </p>
                  <p className="text-h2 font-[700] text-primary mobile:text-h3">
                    {slides[currentSlide]?.title}
                  </p>
                  <hr className="w-[15%] mt-10 mb-5 border-[3px] border-[#FFBB6B] " />
                  <p className="text-body text-primary font-poppins text-justify mt-10">
                    {slides[currentSlide]?.content}
                  </p>
                  <div className="flex gap-5 mt-7 mobile:justify-center mobile:my-5">
                    <img
                      src={prevBtn}
                      alt=""
                      className="cursor-pointer"
                      onClick={goToPreviousSlide}
                    />
                    <img
                      src={nextBtn}
                      alt=""
                      className="cursor-pointer"
                      onClick={goToNextSlide}
                    />
                  </div>
                </div>
                <div className="w-[50%] mobile:w-full flex justify-center items-center">
                  <img
                    src={aboutImg}
                    alt=" "
                    className="w-[80%] mobile:w-full relative"
                  />
                </div>
              </div>
            )}
          </div> */}

        <div className="bg-secondary w-full mt-20">
          <div className="max-w-screen-xl mt-10    flex flex-wrap items-center justify-between mx-auto pt-6 pl-6 pr-6 ">
            <p className="text-h2 text-primary font-bold mt-7">
              Connect With Us{" "}
            </p>
            <p className="text-body text-primary font-normal font-poppins mt-5">
              Follow us on Facebook and Instagram to stay in touch, and get the
              latest updates, news and messages as we <br />
              continue to share the best business practices and strategies that
              work for us, in hopes that it can help you succeed as well.
            </p>
            <div className="grid grid-cols-3 mobile:grid-cols-1 gap-14  mt-20 w-full ">
              <div className="relative rounded-t-[60px] border-[1px] glassbg2 border-white px-4 pt-4">
                <img
                  src={fb}
                  alt=""
                  className=" w-full h-auto rounded-t-[43px]"
                />
                {/* Overlay */}
                <div className="absolute w-[100%]  inset-x-0 bottom-0 h-[22%] bg-[#214284] opacity-[60%]"></div>

                {/* Text */}
                <div className="absolute w-full flex justify-center items-center  bottom-0">
                  <a
                    href="https://www.facebook.com/groups/welcometotfs"
                    className="text-[20px] text-primary bg-tertiary rounded-[35px] mb-5 -ml-5 w-[50%] py-3 px-9 boxShadow font-[600] font-poppins "
                  >
                    {" "}
                    Facebook{" "}
                  </a>
                </div>
              </div>

              <div className=" relative rounded-t-[60px] border-[1px] glassbg2 border-white px-4 pt-4">
                <img
                  src={ig}
                  alt=""
                  className=" w-full h-auto rounded-t-[43px]"
                />
                {/* Overlay */}
                <div className="absolute w-[100%]  inset-x-0 bottom-0 h-[22%] bg-[#214284] opacity-[60%]"></div>

                {/* Text */}
                <div className="absolute w-full flex justify-center items-center  bottom-0">
                  <a
                    href="https://www.instagram.com/nishantarora14/"
                    className="text-[20px] text-primary bg-tertiary rounded-[35px] mb-5 -ml-5 w-[50%] py-3 px-9 boxShadow font-[600] font-poppins "
                  >
                    {" "}
                    Instagram{" "}
                  </a>
                </div>
              </div>
              <div className="relative rounded-t-[60px] border-[1px] glassbg2 border-white px-4 pt-4">
                <img
                  src={telegram}
                  alt=""
                  className="w-full h-auto rounded-t-[43px]"
                />
                {/* Overlay */}
                <div className="absolute w-[100%]  inset-x-0 bottom-0 h-[22%] bg-[#214284] opacity-[60%]"></div>

                {/* Text */}
                <div className="absolute w-full flex justify-center items-center  bottom-0">
                  <a
                    href="https://t.me/welcometotfs14"
                    className="text-[20px] text-primary bg-tertiary rounded-[35px] mb-5 -ml-5 w-[50%] py-3 px-9 boxShadow font-[600] font-poppins "
                  >
                    {" "}
                    Telegram{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonials />
      </div>
    </>
  );
};

export default AboutUs;
