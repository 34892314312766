import React, { useEffect, useState } from 'react';
import liImg from "../Images/li.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlans, updateStatus, registerToEvent } from '../store/actions/website.action';
import Accordian from './Accordian';
import { useNavigate } from 'react-router-dom';

const PricingPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = localStorage?.getItem("token");
    const { planList } = useSelector((state) => state?.web);
    const [activeTab, setActiveTab] = useState("monthly");
    useEffect(() => {
        dispatch(getAllPlans());
    }, [dispatch]);

    const openPayModal = (response, id) => {
        const options = {
            key: "rzp_live_POZ84Rf3NXSoEH",
            amount: response.data.amount,
            order_id: id,
            name: "TFS",
            handler: function (response) {
                dispatch(updateStatus(id, (res) => {
                    if (res.status === 200) {
                        console.log("Payment successful!");
                    }
                }));
            },
            modal: {
                ondismiss: function (res) {
                    dispatch(updateStatus(id, (res) => {
                        if (res.status === 200) {
                            console.log("Payment successful!");
                        }
                    }));
                }
            },
            theme: {
                color: "#214284",
                hide_topbar: true,
            },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const updateOrderIdInSession = (id) => {
        sessionStorage.setItem("orderid", id);
        console.log("id---", id);
    };

    const handleBatchChange = (id) => {
        console.log("id---", id)
        const payload = {
            action: "SUBSCRIPTION",
            actionId: id
        };
        dispatch(registerToEvent(payload, (response) => {
            if (response?.status === 200) {
                openPayModal(response, response?.data?.id);
                updateOrderIdInSession(response?.data?.id);
            } else if (response?.response?.status === 400) {
                const existingOrderId = sessionStorage.getItem("orderid");
                if (existingOrderId) {
                    dispatch(updateStatus(existingOrderId, (updateResponse) => {
                        if (updateResponse?.status === 200) {
                            handleBatchChange();
                        }
                    }));
                }
            }
        }));
    };

    const handleRedirect = (id) => {
        if (data) {
            handleBatchChange(id);
        } else {
            window.location.href = "/login";
        }
    };

    return (
        // <div className='w-full bg-primary'>
        //     <div className='max-w-screen-xl py-16 px-10 mobile:px-5 flex h-full flex-wrap items-center justify-between mx-auto'>
        //         <div className='w-full flex mobile:flex-col gap-14 h-full items-center'>
        //             <Accordian />
        //             {/* <div className='w-[50%] mobile:w-full flex flex-col justify-center items-center '>
        //                 <div className="flex justify-center rounded-[36px] bg-[#FFF] mobile:w-[57%] w-[44%] mt-8 p-2">
        //                     <button
        //                         className={`px-4 py-2 font-[600] font-poppins text-primary rounded-[36px] ${activeTab === "monthly" ? "bg-secondary" : "bg-[#FFF]"}`}
        //                         onClick={() => setActiveTab("monthly")}
        //                     >
        //                         Half Yearly
        //                     </button>
        //                     <button
        //                         className={`px-4 py-2 font-poppins font-[600] text-primary rounded-[36px] ${activeTab === "yearly" ? "bg-secondary text-[#FFF]" : "bg-[#FFF]"}`}
        //                         onClick={() => setActiveTab("yearly")}
        //                     >
        //                         Yearly
        //                     </button>
        //                 </div>
        //                 <div className="mt-10 mobile:w-full">
        //                     {activeTab === "monthly" && (
        //                         <div className='glassbg flex flex-col items-center justify-center p-16 mobile:p-12 w-full'>
        //                             <p className='text-h6 text-white font-poppins font-[600]'>Basic</p>
        //                             <p className='text-h1 font-[600] mobile:mt-5 font-lora text-white'>INR {planList?.[0]?.fees}</p>
        //                             <hr className='border w-full text-white h-[0.5px] opacity-5 mt-10 mobile:my-5 mb-10' />
        //                             <ul className='text-white font-poppins text-[16px] flex flex-col gap-5'>
        //                                 <li className='flex items-center space-x-3 rtl:space-x-reverse'>
        //                                     <img src={liImg} alt="" /> <span>Inclusive of GST</span>
        //                                 </li>
        //                                 <li className='flex items-center space-x-3 rtl:space-x-reverse'>
        //                                     <img src={liImg} alt="" /> <span>Free Platform Access</span>
        //                                 </li>
        //                                 <li className='flex items-center space-x-3 rtl:space-x-reverse'>
        //                                     <img src={liImg} alt="" /> <span>24/7 Customer Support</span>
        //                                 </li>
        //                             </ul>
        //                             <button className="py-4 mt-10 mobile:w-full text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-5" onClick={() => navigate("/getstarted")}>Get Started Today</button>
        //                         </div>
        //                     )}
        //                     {activeTab === "yearly" && (
        //                         <div className='glassbg flex flex-col items-center justify-center p-16 mobile:p-12 w-full'>
        //                             <p className='text-h6 text-white font-poppins font-[600]'>Yearly</p>
        //                             <p className='text-h1 font-[600] mobile:mt-5 font-lora text-white'>INR {planList?.[1]?.fees}</p>
        //                             <hr className='border w-full text-white h-[0.5px] opacity-5 mt-10 mobile:my-5 mb-10' />
        //                             <ul className='text-white font-poppins text-[16px] flex flex-col gap-5'>
        //                                 <li className='flex items-center space-x-3 rtl:space-x-reverse'>
        //                                     <img src={liImg} alt="" /> <span>Inclusive of GST</span>
        //                                 </li>
        //                                 <li className='flex items-center space-x-3 rtl:space-x-reverse'>
        //                                     <img src={liImg} alt="" /> <span>Free Platform Access</span>
        //                                 </li>
        //                                 <li className='flex items-center space-x-3 rtl:space-x-reverse'>
        //                                     <img src={liImg} alt="" /> <span>24/7 Customer Support</span>
        //                                 </li>
        //                             </ul>
        //                             <button className="py-4 mt-10 mobile:w-full text-primary flex justify-center text-body items-center font-[600] text-[20px] font-poppins getInTouch px-5" onClick={() => navigate("/getstarted")}>Get Started Today</button>
        //                         </div>
        //                     )}
        //                 </div>
        //             </div> */}
        //         </div>
        //     </div>
        // </div>
        <div></div>
    );
}

export default PricingPlan;
