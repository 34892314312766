import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import play from "../Images/playbtn.svg";
import quote from "../Images/lightquote.svg";
import photo from "../Images/testphoto.png";
import { useDispatch, useSelector } from "react-redux";
import { contactus } from "../store/actions/auth.action";
import { getAllTestimonials } from "../store/actions/website.action";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { testimonialList } = useSelector((state) => state?.web);
  useEffect(() => {
    const payload = {
      userId: "",
      pageNo: 1,
      perPageResults: 100,
    };
    dispatch(getAllTestimonials(payload));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === testimonialList?.testimonialsList?.length - 1
          ? 0
          : prevSlide + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonialList?.testimonialsList?.length]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    newsletter: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter Your Name *"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Enter Your Email *"),
    phone: Yup.string().required(
      "Enter Your Phone No. Along with Country Code *"
    ),
    subject: Yup.string(),
    message: Yup.string().required("Enter Your Message *"),
    newsletter: Yup.boolean(),
  });

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      emailId: values?.email,
      mobileNo: values?.phone,
      message: values?.message,
      name: values?.name,
      subject: values?.subject,
      subscribe: values?.newsletter || false,
    };
    dispatch(
      contactus(payload, (res) => {
        if (res.status === 200 || res.status === 201) {
          resetForm();
        }
      })
    );
  };
  return (
    <div className="bg-[url('./Images/otherBg.svg')] bg-no-repeat h-full -mt-5 mb-32 ">
      <div className="max-w-screen-xl mb-20 flex flex-wrap items-center justify-between mx-auto p-6 mobile:p-3 ">
        <div className="grid grid-cols-5 mobile:flex-col mobile:flex  gap-10 w-full mt-44">
          <div className="col-span-3 p-10 mobile:p-3">
            <p className="text-secondary font-bold  text-h2 mobile:text-h4">
              Some other great <br />{" "}
              <span className="text-primary"> reasons to choose us</span>
            </p>
            <p className="text-body text-primary font-poppins mt-4">
              Anything worthwhile, needs hard work,dedication,sincerity and
              honesty of intent and efforts.Take the first step to join us and
              we promise to take the next 100 steps with you for your personal
              transformation and financial success.
            </p>
            <div className="bg-[#FF5D27] flex flex-col justify-center items-center w-full rounded-[40px] h-[350px] mt-10">
              <img src={play} alt="" />
            </div>
            <div className="relative">
              <div className="overflow-hidden  w-full">
                <div className="realative w-full">
                  <img src={quote} alt="" className="absolute -mt-10 -z-20" />
                  <p className="text-h5 font-bold text-primary mt-20 mb-3 z-10">
                    Helping Organizations{" "}
                    <span className="text-secondary"> Save Admin Time</span>
                  </p>
                </div>
                <div
                  className="flex transition-transform duration-300 ease-in-out transform"
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  {testimonialList?.testimonialsList?.map((slide, index) => (
                    <div key={index} className="w-full flex-shrink-0">
                      <div className="pt-4">
                        <p className="text-lg font-normal font-poppins text-primary w-[85%] mb-2">
                          {slide.message}
                        </p>
                        <div className="flex gap-5 mt-8 items-center">
                          <img
                            src={slide?.attachments?.[0]}
                            alt=""
                            className="rounded-full w-[110px] h-[110px] object-center object-cover"
                          />
                          <div>
                            <p className="text-h5 text-primary font-bold">
                              {slide.userName}
                            </p>
                            <p className="text-h6 text-secondary font-poppins font-normal mt-2">
                              {slide.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="absolute  mt-5 left-0 w-full flex justify-start ">
                {testimonialList?.testimonialsList.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentSlide(index)}
                    className={`mx-1 w-20 h-2 rounded-full focus:outline-none ${
                      index === currentSlide ? "bg-secondary" : "bg-tertiary"
                    }`}
                  ></button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-2 pt-10 mobile:col-span-auto">
            <div className="bg-white h-full flex flex-col font-bold text-h5 items-center p-7 mobile:p-3 w-full rounded-[20px]">
              <p className="text-primary">Connect with our expert</p>
              <p className="text-secondary font-poppins font-normal text-body mt-3">
                Let's Drop us Message for any Query.
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form className="w-full">
                  <div className="mt-16">
                    <Field
                      type="text"
                      name="name"
                      placeholder="Enter Your Name *"
                      className="p-3.5 w-full font-normal font-poppins text-primary text-[16px] rounded-[39px] focus:border-primary border-2 border-primary focus:ring-transparent focus:outline-none"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="mt-5">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter Your Email *"
                      className="p-3.5 w-full font-normal font-poppins text-primary text-[16px] rounded-[39px] focus:border-primary border-2 border-primary focus:ring-transparent focus:outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="mt-5">
                    <Field
                      type="text"
                      name="phone"
                      placeholder="Enter Your Phone No. Along with Country Code *"
                      className="p-3.5 w-full font-normal font-poppins text-primary focus:border-primary text-[16px] rounded-[39px] border-2 border-primary focus:ring-transparent focus:outline-none"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="mt-5">
                    <Field
                      type="text"
                      name="subject"
                      placeholder="Select or Enter Your Subject"
                      className="p-3.5 w-full font-normal font-poppins text-primary text-[16px] focus:border-primary rounded-[39px] border-2 border-primary focus:ring-transparent focus:outline-none"
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="mt-5">
                    <Field
                      as="textarea"
                      rows={3}
                      name="message"
                      placeholder="Enter Your Message *"
                      className="p-3.5 w-full font-normal font-poppins text-primary text-[16px] focus:border-primary rounded-[20px] border-2 border-primary focus:ring-transparent focus:outline-none"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="flex items-center me-4 justify-start mt-5">
                    <Field
                      id="newsletter"
                      type="checkbox"
                      name="newsletter"
                      className="w-7 rounded-full h-7 text-primary focus:border-primary border-primary focus:ring-primary"
                    />
                    <label
                      htmlFor="newsletter"
                      className="ms-2 text-base font-poppins font-medium text-secondary"
                    >
                      I'm agree for news letter subscription!
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="bg-primary w-full rounded-[36px] h-12 flex justify-center items-center text-[16px] text-white font-poppins font-medium mt-7"
                  >
                    SEND MESSAGE
                  </button>
                </Form>
              </Formik>
              <div className="flex flex-col justify-start items-start text-start w-full p-0">
                <p className="ml-0 pl-0 mt-20 text-body font-body text-primary text-start">
                  During the Call, we will Delve into
                </p>
                <ul className="gap-3 mt-5 flex flex-col text-body font-poppins font-normal text-[#6cc1e9] list-disc">
                  <li>Uncovering your current pain points</li>
                  <li>Explore consequences associated with them</li>
                  <li>Clarifying business goals sought from the program</li>
                  <li>Discuss TFS & TIC alignment with your goals</li>
                  <li>Assess fit and decide on next steps</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
