const ServiceCard = ({ imgUrl, underLine, title, desc }) => {
  return (
    <div className="glassbg2 border-2 border-white lightboxShadow rounded-[30px] h-full w-full p-10 mobile:p-5 tablet:p-5 tablet:rounded-[20px] mobile:rounded-[20px]">
      <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center">
        <img src={imgUrl} alt="" className=" absolute w-9" />
      </div>
      <p className="text-primary text-[36px] font-bold mt-5">{title}</p>
      <img src={underLine} alt="" className="mb-3" />
      <p className="text-body font-normal font-poppins text-primary ">{desc}</p>
    </div>
  );
};

export default ServiceCard;
