import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import locationIcon from "../Images/location.png";
import dateIcon from "../Images/date.png";
import regUserIcon from "../Images/regUser.png";
import regFileIcon from "../Images/regFile.png";
import { getEventbyId, registerToEvent, updateStatus } from '../store/actions/website.action';

const EventDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [orderId, setOrderId] = useState("");
    const eventbyId = useSelector((state) => state?.web?.eventbyId);
    const successOrder = sessionStorage.getItem("orderid");

    useEffect(() => {
        dispatch(getEventbyId(id));
    }, [dispatch, id]);

    const openPayModal = (response, id) => {
        const options = {
            key: "rzp_live_POZ84Rf3NXSoEH",
            amount: response.data.amount,
            name: "TFS",
            order_id: id,
            handler: function (response) {
                const paymentId = response.razorpay_payment_id;
                dispatch(updateStatus(id, (res) => {
                    if (res.status === 200) {
                        console.log("Payment successful!");
                    }
                }));
            },
            'modal': {
                'ondismiss': function (res) {
                    dispatch(updateStatus(id, (res) => {
                        if (res.status === 200) {
                            console.log("Payment successful!");
                        }
                    }));
                }
            },
            theme: {
                color: "#214284",
                hide_topbar: true,
            },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const updateOrderIdInSession = (id) => {
        sessionStorage.setItem("orderid", id);
        console.log("id---", id)
    };
    const handleEventReserve = (id) => {
        const payload = {
            action: "EVENT",
            actionId: id,
        };
        dispatch(registerToEvent(payload, (response) => {
            if (response?.status === 200) {
                openPayModal(response);
                updateOrderIdInSession(response.data.id);
                sessionStorage.setItem("orderid", response.data.id);
                setOrderId(response.data.id);
            } else if (response?.response?.status === 400) {
                const existingOrderId = sessionStorage.getItem("orderid");
                if (existingOrderId) {
                    dispatch(updateStatus(existingOrderId, (updateResponse) => {
                        if (updateResponse?.status === 200) {
                            sessionStorage.setItem("orderid", updateResponse.data.razorPayOrderId);
                            handleEventReserve(updateResponse.data.actionId);
                        }
                    }));
                }
            }
        }));
    };

    return (
        <div className="bg-tertiary bg-no-repeat h-full -mt-5">
            <div className="flex flex-col justify-center items-center h-full w-full px-48 mobile:px-0">
                <div className="bg-white w-full mt-36 rounded-[40px] h-[60px] ">
                    <div className="w-full flex items-center gap-5 p-2 mobile:p-3">
                        <Link to="/insiescreen">
                            <div className="bg-secondary rounded-full h-[44px] w-[44px] flex justify-center items-center cursor-pointer">
                                <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.719296 8.55998C0.719296 8.26033 0.846066 7.96071 1.09907 7.73225L9.06466 0.543009C9.57137 0.0856798 10.3929 0.0856798 10.8994 0.543009C11.4059 1.00015 11.4059 1.74149 10.8994 2.19885L3.85103 8.55998L10.8992 14.9212C11.4057 15.3785 11.4057 16.1197 10.8992 16.5768C10.3927 17.0344 9.57112 17.0344 9.06441 16.5768L1.09883 9.38772C0.845779 9.15915 0.719296 8.85953 0.719296 8.55998Z" fill="#214284" />
                                </svg>
                            </div>
                        </Link>
                        <div>
                            <p className="text-primary text-xl font-poppins font-semibold">Event Details</p>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full mt-10 flex mobile:flex-col mobile:rounded-none rounded-[40px] bg-white">
                    <div className="w-[50%] h-full mobile:w-full">
                        <img src={eventbyId?.attachments?.[0]} alt="" className="rounded-l-[40px] mobile:rounded-none h-[400px] min-w-[100%] object-cover" />
                    </div>
                    <div className="w-[50%] py-2 px-5 mobile:w-full">
                        <p className="text-2xl font-bold text-primary">{eventbyId?.eventName}</p>
                        <p className="text-base font-normal font-poppins text-primary mt-3">Event Topic | 1hr 30min</p>
                        <div className="w-full flex mt-4 items-center mb-5">
                            <div>
                                <img src={dateIcon} alt="" />
                            </div>
                            <div className="pl-3  w-full">
                                <div className="flex w-full justify-between mb-1">
                                    <div className="text-primary font-poppins text-base">Date</div>
                                    <div className="text-primary font-poppins text-base font-medium">{eventbyId?.eventDate}</div>
                                </div>
                                <hr className="border-tertiary border-b-2" />
                                <div className="flex w-full justify-between  mt-1">
                                    <div className="text-primary font-poppins text-base">Time</div>
                                    <div className="text-primary font-poppins text-base font-medium">{eventbyId?.eventStartTime} - {eventbyId?.eventEndTime} (IST)</div>
                                </div>
                            </div>
                        </div>
                        <hr className="border-tertiary border-b-2" />
                        <div className="w-full flex mt-5 items-center mb-2">
                            <div>
                                <img src={locationIcon} alt="" />
                            </div>
                            <div className="pl-3 w-full">
                                <div className="flex w-full justify-between mb-1">
                                    <div className="text-primary font-poppins text-base">Location</div>
                                    <div className="text-primary font-poppins text-base font-medium">{eventbyId?.eventType}</div>
                                </div>
                            </div>
                        </div>
                        <hr className="border-tertiary border-b-2" />
                        <div className="w-full flex justify-between mobile:flex-col items-center mt-2">
                            <p className="text-lg font-medium mobile:flex mobile:justify-start text-primary font-poppins">Are you Attending?</p>
                            <div className="bg-primary text-white font-poppins mobile:px-5 mobile:flex mobile:mt-6 mb-4 text-base font-medium px-16 rounded-[31px] py-3 cursor-pointer" onClick={() => handleEventReserve(eventbyId?.eventId)}>
                                Reserve Spot
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full mt-10 flex mobile:flex-col gap-3 mb-20">
                    <div className="w-[50%] mobile:w-full rounded-[20px] mobile:rounded-none mobile:p-6  bg-white p-6">
                        <p className="text-primary text-lg font-medium font-poppins">About The Event</p>
                        <p className="text-primary text-base font-normal text-justify font-poppins mt-5">
                            <div dangerouslySetInnerHTML={{ __html: eventbyId?.description }} />
                        </p>
                    </div>
                    <div className="w-[50%] mobile:w-full">
                        <div className="flex w-[90%] mobile:w-full bg-white py-3 px-4 justify-between items-center rounded-[20px] mobile:rounded-none">
                            <div className="flex items-center gap-3">
                                <img src={regUserIcon} alt="" />
                                <p className="font-poppins text-primary text-base">Registered Users</p>
                            </div>
                            <div className="font-poppins text-primary text-base font-medium">
                                {eventbyId?.currentRegistrations}
                            </div>
                        </div>
                        <div className="flex w-[90%] mobile:w-full bg-white py-3 mt-5 px-4 justify-between items-center rounded-[20px] mobile:rounded-none">
                            <div className="flex items-center gap-3">
                                <img src={regFileIcon} alt="" />
                                <p className="font-poppins text-primary text-base">Event Related Files</p>
                            </div>
                            <div className="font-poppins text-primary text-base font-medium px-8 mobile:px-1 py-2  border border-primary rounded-[42px] mobile:rounded-4xl">
                                View File
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EventDetails;
