import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FileUpload from "./FileUpload";
import Button from "./Button";
import axios from "axios";

// Function to convert File to Base64
const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject(new Error("Failed to read file as Base64"));
        reader.readAsDataURL(file);
    });
};


const KycVerification = ({ onNext, onBack }) => {
    const [initialValues, setInitialValues] = useState({
        profilePhoto: null,
        idProofs: [],
    });
    const [uploadCount, setUploadCount] = useState(1);

    // ----------------------------------------- Fetch stored form data -----------------------------------------
    useEffect(() => {
        const storedValues = localStorage.getItem("kycFormData");
        if (storedValues) {
            const parsedValues = JSON.parse(storedValues);
            setInitialValues({
                profilePhoto: parsedValues?.profilePhoto || null,
                idProofs: parsedValues?.idProofs || [],
            });
            setUploadCount(parsedValues?.idProofs.length || 1);
        }
    }, []);

    // ----------------------------------------- Validation Schema -----------------------------------------
    const validationSchema = Yup.object({
        profilePhoto: Yup.mixed().required("Profile photo is required"),
        idProofs: Yup.array()
            .of(Yup.mixed().required("ID proof is required"))
            .min(1, "At least one ID proof is required")
            .max(4, "You can upload a maximum of 4 ID proofs"),
    });

    // ----------------------------------------- Handle File Upload -----------------------------------------
    const handleFileUpload = (file, setFieldValue, fieldName) => {
        if (file) {
            setFieldValue(fieldName, file);
            if (fieldName.startsWith("idProofs") && uploadCount < 4) {
                setUploadCount((prevCount) => prevCount + 1);
            }
        }
    };

    // ----------------------------------------- On Submit function -----------------------------------------
    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // Convert profilePhoto to Base64
            const base64ProfilePhoto = await convertToBase64(values.profilePhoto);

            // Convert each ID proof to Base64
            const base64IdProofs = await Promise.all(
                values.idProofs.map((file) => convertToBase64(file))
            );

            // Step 1: Upload profile photo
            const profilePhoto = values.profilePhoto;
            const profilePhotoSignedUrlResponse = await axios.get(
                `https://rudf4zn65l.execute-api.ap-south-1.amazonaws.com/dev/getSignedUrl?mediaType=profilePhoto&fileName=${profilePhoto.name}`,
                { headers: { Authorization: localStorage.getItem("token") } }
            );

            if (profilePhotoSignedUrlResponse.status === 200) {
                const profilePhotoSignedUrl =
                    profilePhotoSignedUrlResponse.data.data.s3SignedUrl;
                await axios.put(profilePhotoSignedUrl, profilePhoto, {
                    headers: { "Content-Type": profilePhoto.type },
                });
                const profilePhotoImageUrl = profilePhotoSignedUrl.split("?")[0];
                values.profilePhotoImageUrl = profilePhotoImageUrl;
            } else {
                throw new Error("Failed to get signed URL for profile photo upload");
            }

            // Step 2: Upload ID proofs
            const idProofsSignedUrls = [];
            for (let i = 0; i < values.idProofs.length; i++) {
                const idProof = values.idProofs[i];
                const idProofSignedUrlResponse = await axios.get(
                    `https://rudf4zn65l.execute-api.ap-south-1.amazonaws.com/dev/getSignedUrl?mediaType=idProof&fileName=${idProof.name}`,
                    { headers: { Authorization: localStorage.getItem("token") } }
                );

                if (idProofSignedUrlResponse.status === 200) {
                    const idProofSignedUrl = idProofSignedUrlResponse.data.data.s3SignedUrl;
                    await axios.put(idProofSignedUrl, idProof, {
                        headers: { "Content-Type": idProof.type },
                    });
                    const idProofImageUrl = idProofSignedUrl.split("?")[0];
                    idProofsSignedUrls.push(idProofImageUrl);
                } else {
                    throw new Error("Failed to get signed URL for ID proof upload");
                }
            }

            // Set values in localStorage when all uploads are successful (status 200)
            if (profilePhotoSignedUrlResponse.status === 200 && idProofsSignedUrls.length === values.idProofs.length) {
                console.log(values?.profilePhotoImageUrl, idProofsSignedUrls)
                localStorage.setItem(
                    "kycFormData",
                    JSON.stringify({
                        profilePhoto: values.profilePhoto,
                        idProofs: values.idProofs,
                    })
                );

                // Proceed with onNext when all is successful
                onNext({
                    profilePhoto: values.profilePhotoImageUrl,
                    idProofs: idProofsSignedUrls,
                });
            }

            setSubmitting(false);
        } catch (error) {
            console.error("Error submitting KYC verification:", error);
            setSubmitting(false);
        }
    };

    return (
        <div className="w-full flex justify-center items-center relative">
            <div className="mobile:bg-[#fffaed] w-full flex justify-center items-center">
                <div className="w-full">
                    <h2 className="text-2xl text-center text-[#214284] font-lora font-semibold laptop:hidden mobile:p-6 mobile:bg-[#fffaed]">
                        Please Fill In This KYC Form To Help Us Know You Better!
                    </h2>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ setFieldValue, handleSubmit }) => (
                            <Form>
                                <div className="flex items-center space-x-2 p-3 px-8">
                                    <h3 className="mobile:p-4 text-3xl mb-4 font-lora font-bold mobile:mt-5 text-[#214284]">
                                        Upload Document
                                    </h3>
                                </div>
                                <div className="space-y-4 mobile:p-6">
                                    <div className="px-8">
                                        <label className="block text-lg text-primary font-semibold font-poppins mb-2">
                                            Profile Photo <span className="text-[#ffda36]">*</span>
                                        </label>
                                        <FileUpload fileType="profilephoto"
                                            onUpload={(file) =>
                                                handleFileUpload(file, setFieldValue, "profilePhoto")
                                            }
                                        />
                                        <ErrorMessage
                                            name="profilePhoto"
                                            component="div"
                                            className="text-red-500"
                                        />
                                    </div>

                                    <div className="px-8">
                                        <label className="block text-lg font-semibold font-poppins text-primary mb-2">
                                            Attach your ID Proof (Aadhaar, Passport, Voter-ID, Driving License){" "}
                                            <span className="text-[#ffda36]">*</span>
                                        </label>
                                        {Array.from({ length: uploadCount }).map((_, index) => (
                                            <div key={index}>
                                                <FileUpload
                                                    fileType="idproof"
                                                    setUploadCount={setUploadCount}
                                                    uploadCount={uploadCount}
                                                    onUpload={(file) =>
                                                        handleFileUpload(
                                                            file,
                                                            setFieldValue,
                                                            `idProofs[${index}]`
                                                        )
                                                    }
                                                />
                                                <ErrorMessage
                                                    name={`idProofs[${index}]`}
                                                    component="div"
                                                    className="text-red-500"
                                                />
                                            </div>
                                        ))}
                                    </div>

                                    <hr className="border-t-2 opacity-16 mb-4 mt-4" />
                                    <div className="flex w-full flex-col px-8 md:flex-row md:space-y-0 md:space-x-3 mobile:justify-center mobile:items-center">
                                        <div className="mobile:justify-center items-center w-full md:w-1/2 md:justify-start text-[#214284]">
                                            <div onClick={onBack} className="w-full md:w-auto">
                                                <Button label="Back" type="button" variant="outline" />
                                            </div>
                                        </div>
                                        <div className="mobile:justify-center flex items-center w-full md:w-1/2 md:justify-end">
                                            <div className="w-full md:w-auto">
                                                <Button
                                                    label="Next"
                                                    type="submit"
                                                    variant="solid"
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default KycVerification;
