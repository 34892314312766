import React, { useEffect, useState, useRef } from 'react';
import blogEx from "../Images/blogex.png";
import videoImg from "../Images/podcast.png";
import share from "../Images/share.svg";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { blogList } from '../store/actions/website.action';
import Pagination from '../components/Resources/Pagination';
import toast from 'react-hot-toast';

const Blogs = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(9); // Number of blogs per page
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [shareIndex, setShareIndex] = useState(null);
    const shareRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { allBlogs, loader } = useSelector((state) => state?.web);

    useEffect(() => {
        const fetchBlogs = async () => {
            dispatch(blogList());
        };
        fetchBlogs();
    }, [dispatch]);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const navigateToDetailPage = (index) => {
        const blogId = index;
        const url = `/blogs/${blogId}`;
        navigate(url);
    };

    const handleShareClick = (index) => {
        setShareIndex(index);
        setShowShareOptions((prev) => !prev);
    };

    const handleCopyLink = (link) => {
        navigator?.clipboard?.writeText(link);
        toast.success(<p className='text-primary font-poppins'>Link copied to clipboard!</p>);
    };

    const truncateTitle = (title, maxLength) => {
        if (title && title?.length <= maxLength) return title;
        return `${title?.substring(0, maxLength)}...`;
    };

    // Get current blogs
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = allBlogs?.blogList?.slice(indexOfFirstBlog, indexOfLastBlog);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const currentBlog = currentBlogs?.[0];

    const handleClickOutside = (event) => {
        if (shareRef.current && !shareRef.current.contains(event.target)) {
            setShowShareOptions(false);
        }
    };

    useEffect(() => {
        if (showShareOptions) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showShareOptions]);

    return (
        <div className="bg-[url('https://tfspublic.s3.ap-south-1.amazonaws.com/images/Blog/background.png')] bg-contain bg-no-repeat h-full -mt-5 mb-32 ">
            <div className='flex flex-col justify-center items-center '>
                <p className='text-secondary text-h5 font-bold	mt-40'>Blogs</p>
                <p className='text-primary font-bold mt-9 text-center text-h2 mobile:text-h4 font-lora'>Empower Your Trading Journey: <br /> <span className='text-secondary'>
                    Discover Valuable Resources in Our Blog</span></p>
            </div>
            <div className='bg-primary rounded-[60px] mobile:rounded-none mobile:flex-col max-w-screen-xl mt-24 mb-20 flex flex-wrap items-center justify-between mx-auto p-6'>
                <div className='flex w-full gap-10 mobile:flex-col'>
                    <div className='w-[50%] pl-7 mobile:pl-0 mobile:w-full mobile:flex mobile:justify-center mobile:flex-col'>
                        <p className='darkbg text-white w-[40%] flex justify-center mobile:w-[100%] font-poppins'>Stock Market</p>
                        <p className='text-h2 mt-5 capitalize text-white font-bold mobile:text-h4 mobile:text-center'>
                            {truncateTitle(currentBlog?.title, 45)}
                        </p>
                        <p className='mt-5 text-[18px] text-[#FDF3DA] font-poppins mobile:text-center'>
                            Publish on {currentBlog?.createdDate} | 1 min read
                        </p>
                        <p
                            className='boxShadow mt-16 bg-secondary cursor-pointer rounded-[35px] w-[30%] text-primary py-2 flex justify-center text-[20px] font-[600] font-poppins h-[12%] items-center mobile:w-full'
                            onClick={() => navigateToDetailPage(currentBlog?.blogId)}
                        >
                            Read Now
                        </p>
                    </div>
                    <div className='w-[50%] mobile:w-full'>
                        {currentBlog?.attachments?.length > 0 ? (
                            <img src={currentBlog?.attachments[0]} alt={currentBlog?.attachments.title} className="w-full max-h-[300px] min-h-[270px] object-cover rounded-[30px]" />
                        ) : (
                            <img src={videoImg} alt="Default" className="w-full h-auto" />
                        )}
                    </div>
                </div>
            </div>
            {loader ? (
                <div class="text-center">
                    <div role="status">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div className='max-w-screen-xl mt-24 mb-20 flex flex-wrap items-center justify-between mx-auto p-6'>
                    <div className="grid grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-14 w-full">
                        {currentBlogs?.map((blog, index) => (
                            <div
                                key={index}
                                className="rounded-[32px] mobile:rounded-[20px] border-[1px] glassbg2 border-white px-3 py-3"
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                    backgroundColor: hoveredIndex === index ? "#ffbb6b" : "transparent",
                                }}
                            >
                                <div className="">
                                    <div className="relative">
                                        {blog?.attachments?.length > 0 ? (
                                            <img src={blog.attachments[0]} alt={blog.title} className="w-full max-h-[300px] min-h-[270px] object-cover rounded-[30px]" />
                                        ) : (
                                            <img src={videoImg} alt="Default" className="w-full h-auto" />
                                        )}
                                    </div>
                                    <p className="text-body font-poppins font-normal text-primary mt-3 mb-3">
                                        Publish on {blog.createdDate} | 1 min read
                                    </p>
                                    <p
                                        className="text-h5 text-primary font-bold w-[76%] leading-[40px]"
                                        title={blog?.title}
                                    >
                                        {truncateTitle(blog.title, 40)}
                                    </p>
                                    <div className="flex justify-between mt-5">
                                        <div
                                            className={`cursor-pointer text-h6 w-fit font-semibold font-poppins text-primary px-7 py-2 rounded-[35px] ${hoveredIndex === index ? "bg-white" : "bg-secondary"}`}
                                            onClick={() => navigateToDetailPage(blog?.blogId)}
                                        >
                                            Read Now
                                        </div>
                                        <div className="flex gap-3 items-center min-w-fit  justify-end pr-5 relative">
                                            <img src={'https://tfspublic.s3.ap-south-1.amazonaws.com/images/Blog/share.png'} alt="Share" onClick={() => handleShareClick(index)} className='w-5 cursor-pointer' />
                                            <p className="text-body font-poppins font-normal text-primary cursor-pointer" onClick={() => handleShareClick(index)} >
                                                Share
                                            </p>
                                            {showShareOptions && shareIndex === index && (
                                                <div ref={shareRef} className="absolute font-poppins min-w-[250px] top-8 right-0 bg-white border border-gray-200 rounded-md shadow-lg z-10 p-3" onClick={(e) => {
                                                    if (e.currentTarget === e.target) setShowShareOptions(false);
                                                }}>
                                                    <div className="cursor-pointer flex gap-3 text-primary mb-2" onClick={() => handleCopyLink(`http://welcometotfs.com/#/blogs/${blog.blogId}`)}>
                                                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                            <path stroke="#212482" stroke-linejoin="round" stroke-width="2" d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z" />
                                                        </svg>
                                                        Copy Link
                                                    </div>
                                                    <div className="cursor-pointer flex mb-2">
                                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=http://welcometotfs.com/#/blog/${blog.blogId}`} className='flex text-primary gap-3' target="_blank" rel="noopener noreferrer">
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M26.9427 14C26.9427 13.605 26.9222 13.2151 26.8876 12.8296H28C27.4364 6.00324 21.9968 0.563604 15.1704 0V1.11243C14.7845 1.07784 14.3946 1.0573 14 1.0573C13.6054 1.0573 13.2151 1.07784 12.8295 1.11243V0C6.00324 0.563604 0.563604 6.00324 0 12.8296H1.11243C1.07784 13.2155 1.05729 13.6054 1.05729 14C1.05729 14.395 1.07784 14.7849 1.11243 15.1705H0C0.563604 21.9968 6.00324 27.4364 12.8295 28V26.8876C13.2155 26.9222 13.6054 26.9427 14 26.9427C14.3946 26.9427 14.7849 26.9222 15.1704 26.8876V28C21.9968 27.4364 27.4364 21.9968 28 15.1705H26.8876C26.9222 14.7849 26.9427 14.395 26.9427 14Z" fill="#214284" />
                                                                <path d="M13.9999 26.0837C7.33753 26.0837 1.91699 20.6636 1.91699 14.0009C1.91699 7.33851 7.33717 1.91797 13.9999 1.91797C20.6622 1.91797 26.0828 7.33815 26.0828 14.0009C26.0828 20.6632 20.6626 26.0837 13.9999 26.0837ZM13.9999 2.15184C7.46618 2.15184 2.15086 7.46716 2.15086 14.0009C2.15086 20.5345 7.46618 25.8499 13.9999 25.8499C20.5336 25.8499 25.8489 20.5345 25.8489 14.0009C25.8489 7.46752 20.5336 2.15184 13.9999 2.15184Z" fill="white" />
                                                                <path d="M17.7748 9.53602L16.4065 9.53674C15.3337 9.53674 15.1258 10.0466 15.1258 10.7948V12.4445H17.6847L17.684 15.0286H15.1261V21.6596H12.4569V15.0286H10.2256V12.4445H12.4569V10.5389C12.4569 8.32737 13.8079 7.12305 15.7809 7.12305L17.7748 7.12593V9.53602Z" fill="white" />
                                                            </svg>

                                                            Share on Facebook</a>
                                                    </div>
                                                    <div className="cursor-pointer flex mb-2">
                                                        <a className='flex gap-3 text-primary' href={`https://www.instagram.com/?url=http://welcometotfs.com/#/blog/${blog.blogId}`} target="_blank" rel="noopener noreferrer">
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M26.9427 14C26.9427 13.605 26.9222 13.2151 26.8876 12.8295H28C27.4364 6.00324 21.9968 0.563604 15.1705 0V1.11243C14.7845 1.07783 14.3946 1.05729 14 1.05729C13.605 1.05729 13.2151 1.07783 12.8296 1.11243V0C6.00324 0.563604 0.563604 6.00324 0 12.8295H1.11243C1.07784 13.2155 1.0573 13.6054 1.0573 14C1.0573 14.395 1.07784 14.7849 1.11243 15.1704H0C0.563604 21.9968 6.00324 27.4364 12.8296 28V26.8876C13.2155 26.9222 13.6054 26.9427 14 26.9427C14.395 26.9427 14.7849 26.9222 15.1705 26.8876V28C21.9968 27.4364 27.4364 21.9968 28 15.1704H26.8876C26.9222 14.7849 26.9427 14.395 26.9427 14Z" fill="#214284" />
                                                                <path d="M13.9999 26.0828C7.33753 26.0828 1.91699 20.6622 1.91699 13.9999C1.91699 7.33753 7.33753 1.91699 13.9999 1.91699C20.6622 1.91699 26.0828 7.33753 26.0828 13.9999C26.0828 20.6622 20.6622 26.0828 13.9999 26.0828ZM13.9999 2.15123C7.46618 2.15123 2.15087 7.46654 2.15087 14.0002C2.15087 20.5339 7.46618 25.8492 13.9999 25.8492C20.5336 25.8492 25.8489 20.5339 25.8489 14.0002C25.8489 7.46654 20.5332 2.15123 13.9999 2.15123Z" fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.487 14.0007C18.487 11.5924 16.5356 9.64285 14.1299 9.64285C11.7226 9.64285 9.7731 11.5924 9.7731 14.0007C9.7731 16.4065 11.7226 18.3556 14.1299 18.3556C16.5356 18.356 18.487 16.4068 18.487 14.0007ZM18.4037 9.05042C18.0153 9.05042 17.7025 9.36682 17.7025 9.7524C17.7025 10.1409 18.0153 10.4529 18.4037 10.4529C18.7893 10.4529 19.1057 10.1409 19.1057 9.7524C19.1057 9.36682 18.7893 9.05042 18.4037 9.05042ZM10.5691 8.04393H17.6902C19.0099 8.04393 20.0859 9.12249 20.0859 10.4378V17.5618C20.0859 18.8803 19.0099 19.9556 17.6902 19.9556H10.5691C9.25129 19.9556 8.17382 18.8803 8.17382 17.5618V10.4378C8.17382 9.12249 9.25165 8.04393 10.5691 8.04393ZM17.6906 7.24609H10.5695C8.81417 7.24609 7.37598 8.68321 7.37598 10.4378V17.5618C7.37598 19.3196 8.81417 20.7553 10.5695 20.7553H17.6906C19.4491 20.7553 20.8841 19.3196 20.8841 17.5618V10.4378C20.8841 8.68321 19.4491 7.24609 17.6906 7.24609ZM10.6787 14.0007C10.6787 12.0937 12.2246 10.547 14.1299 10.547C16.0372 10.547 17.581 12.0937 17.581 14.0007C17.581 15.9052 16.0369 17.4519 14.1299 17.4519C12.2246 17.4522 10.6787 15.9056 10.6787 14.0007Z" fill="white" />
                                                            </svg>

                                                            Share on Instagram
                                                        </a>
                                                    </div>
                                                    <div className="cursor-pointer  mb-2">
                                                        <a className='flex gap-3 text-primary' href={`https://telegram.me/share/url?url=http://welcometotfs.com/blogs/${blog.blogId}&text=${blog.title}`} target="_blank" rel="noopener noreferrer">
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M26.9427 14C26.9427 13.605 26.9222 13.2151 26.8876 12.8295H28C27.4364 6.00324 21.9968 0.563604 15.1704 0V1.11243C14.7845 1.07783 14.3946 1.05729 14 1.05729C13.6054 1.05729 13.2151 1.07783 12.8295 1.11243V0C6.00324 0.563604 0.563604 6.00324 0 12.8295H1.11243C1.07784 13.2155 1.05729 13.6054 1.05729 14C1.05729 14.395 1.07784 14.7849 1.11243 15.1704H0C0.563604 21.9968 6.00324 27.4364 12.8295 28V26.8876C13.2155 26.9222 13.6054 26.9427 14 26.9427C14.3946 26.9427 14.7849 26.9222 15.1704 26.8876V28C21.9968 27.4364 27.4364 21.9968 28 15.1704H26.8876C26.9222 14.7849 26.9427 14.395 26.9427 14Z" fill="#214284" />
                                                                <path d="M13.9999 26.0828C7.33753 26.0828 1.91699 20.6622 1.91699 13.9999C1.91699 7.33753 7.33717 1.91699 13.9999 1.91699C20.6622 1.91699 26.0828 7.33753 26.0828 13.9999C26.0828 20.6622 20.6626 26.0828 13.9999 26.0828ZM13.9999 2.15123C7.46618 2.15123 2.15086 7.46654 2.15086 14.0002C2.15086 20.5339 7.46618 25.8492 13.9999 25.8492C20.5336 25.8492 25.8489 20.5339 25.8489 14.0002C25.8489 7.46654 20.5336 2.15123 13.9999 2.15123Z" fill="white" />
                                                                <path d="M11.8636 16.7559L11.7529 19.5555L13.1991 17.7923L11.8636 16.7559Z" fill="white" />
                                                                <path d="M19.7385 8.13001C17.4308 9.05866 9.98286 12.1632 7.16376 13.3394C6.79151 13.4947 6.8171 14.0298 7.20196 14.1498L10.4347 15.1585L17.6823 10.7642C17.7266 10.7376 17.7721 10.7967 17.7346 10.8323L12.1933 16.1001C12.1933 16.1001 15.6002 18.5952 17.2164 19.7736C17.6146 20.0637 18.1793 19.8406 18.2701 19.3563C18.7346 16.8832 19.896 10.6997 20.2928 8.58586C20.353 8.26767 20.0387 8.00893 19.7385 8.13001Z" fill="white" />
                                                            </svg>

                                                            Share on Telegram
                                                        </a>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {currentBlogs?.length > 9 &&
                        <div className='flex justify-center w-full'>
                            <Pagination currentPage={currentPage} totalPages={Math.ceil(allBlogs?.blogList?.length / blogsPerPage)} paginate={paginate} />
                        </div>}
                </div>
            )}
        </div>
    );
};

export default Blogs;
