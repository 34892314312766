import React from 'react'

const Loader = ({ setIsLoading }) => {
    return (
        <>
            <div className="overflow-y-auto overflow-x-hidden fixed z-[999] bg-black bg-opacity-[0.6] justify-center h-full flex items-center w-full inset-0 max-h-full font-raleway" onClick={(e) => {
                e.currentTarget === e.target && setIsLoading(false);
            }}>
                <div className="flex justify-center items-center h-screen ">
                    <div className="w-16 h-16 border-4 border-gray-300 border-t-blue-600 rounded-full animate-spin"></div>
                </div>
            </div>
        </>
    )
}

export default Loader