import { SocialIcon } from "react-social-icons";

const SocialLink = ({ text, url }) => {
  return (
    <a
      className="bg-tertiary h-[55px] rounded-[35px] text-primary flex justify-center items-center font-poppins font-semibold text-[20px] w-[75%]"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className="w-[100%]  flex justify-center items-center gap-2">
        {text} <SocialIcon url={url} />
      </p>
    </a>
  );
};

export default SocialLink;
