import {
  Homepage,
  AboutUs,
  Resources,
  Pricing,
  Blogs,
  ContactUs,
  Portal,
  InsieAdmin,
  Profile,
  Login,
  KycComplete,
  KycDeclaration,
  KycVerification,
  GetStarted,
  GetPayment,
  Confirmation,
  KYCFormComponent,
  BlogDetail,
  EventDetails,
  KycCompleteToDashboard,
} from "../pages";

export default {
  public: [
    { path: "/home", element: <Homepage /> },
    { path: "/aboutus", element: <AboutUs /> },
    { path: "/resources", element: <Resources /> },
    { path: "/pricing", element: <Pricing /> },
    { path: "/blogs", element: <Blogs /> },
    { path: "/blogs/:id", element: <BlogDetail /> },
    { path: "/contactus", element: <ContactUs /> },
    { path: "/portal", element: <Portal /> },
  ],
  general: [{ path: "/login", element: <Login /> }],
  login: [
    { path: "/insiescreen", element: <InsieAdmin /> },
    { path: "/events/:id", element: <EventDetails /> },
    { path: "/scheduled/:id", element: <BlogDetail /> },
    { path: "/profile", element: <Profile /> },
  ],
  admin: [
    { path: "/KycComplete", element: <KycComplete /> },
    { path: "/KycDeclaration", element: <KycDeclaration /> },
    { path: "/kycform", element: <KYCFormComponent /> },
    { path: "/kycVerification", element: <KycVerification /> },
    { path: "/getstarted", element: <GetStarted /> },
    { path: "/ktdash", element: <KycCompleteToDashboard /> },
    { path: "/kyc-payment", element: <GetPayment /> },
    { path: "/confirmation", element: <Confirmation /> },
  ],
};
