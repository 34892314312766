import React, { useState } from "react";
import logo from "../Images/logo.svg";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { subscribeForm } from "../store/actions/website.action";
import toast from "react-hot-toast";
import CreateTestimonial from "../pages/CreateTestimonial";
import Loader from "./Loader";
import SocialLink from "./SocialLink";
import QuickLink from "./QuickLink";
import { quickLinks, socialLinks, companyLinks } from "../data/appData";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Footer = () => {
  const [initialPopup, setInitialPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to manage loader
  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };
  const initialValues = {
    name: "",
    emailId: "",
  };
  const dispatch = useDispatch();
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true); // Start loader
    dispatch(
      subscribeForm(values, (response) => {
        setIsLoading(false); // Stop loader after response
        if (response?.status === 200) {
          toast.success("Email Subscribe Successfully!");
          resetForm();
        } else if (response?.response?.status === 400) {
          toast.error("Subscription failed. Please try again.");
        }
        setSubmitting(false);
      })
    );
  };

  return (
    <>
      {initialPopup && <CreateTestimonial setInitialPopup={setInitialPopup} />}
      {isLoading && <Loader setIsLoading={setIsLoading} />}
      <div className="mobile:overflow-x-hidden">
        {/* <div className="w-full   bg-cover bg-center mobile:mb-7 mt-0"> */}
        {/* min-h-[558px] bg-form-bg */}
        {/* <div className='max-w-screen-xl py-16 px-10 mobile:py-2 flex h-full flex-wrap items-center justify-between mx-auto w-full bg-section-bg mobile:h-full mobile:bg-secondary bg-center bg-no-repeat min-h-[342px] z-30 relative'>
                       
                        <div className='w-full flex mobile:flex-col items-center gap-16 pt-[80px] mobile:pt-2 mobile:gap-5'>
                            <div className='w-[20%] mobile:hidden'>
                                <img src='https://tfspublic.s3.ap-south-1.amazonaws.com/images/Footer/bull.png' alt='' className='mobile:w-[50%]' />
                            </div>
                            <div className='w-[80%] mobile:w-full'>
                                <p className='text-[36px] font-[700] text-white mobile:text-xl'>We always try to be as close to you as possible</p>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className='grid grid-cols-3 mobile:w-full mobile:pb-5 mobile:mt-2 mobile:gap-0 w-[93%] mt-8 gap-5 mobile:grid-cols-1'>
                                                <div className="mt-5 mobile:mt-2">
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full py-[17px]"
                                                        placeholder="Enter Your Name"
                                                    />
                                                    <ErrorMessage name="name" component="div" className="text-red-500" />
                                                </div>
                                                <div className="mt-5  mobile:mt-2">
                                                    <Field
                                                        type="email"
                                                        name="emailId"
                                                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full py-[17px]"
                                                        placeholder="Enter Your Email"
                                                    />
                                                    <ErrorMessage name="emailId" component="div" className="text-red-500" />
                                                </div>
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className="mt-5  mobile:mt-2 font-semibold cursor-pointer disabled:cursor-wait rounded-[33px] text-center text-[20px] font-poppins text-white bg-primary mobile:py-3  w-full py-[17px]"
                                                >
                                                    Subscribe Now
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div> */}
        {/* <div className="relative -bottom-7 left-0  flex justify-center mobile:w-full laptop:hidden">
                        <img src={SubscribeImg} alt="" className=" mobile:w-[70%]" />
                    </div> */}
        {/* </div> */}
        {/* <div className="bg-primary overflow-x-hidden h-[60px]  -ml-10 mobile:ml-0 mobile:w-full w-[2035px]  z-10 absolute -rotate-6 flex gap-20 items-center mobile:-mt-7 -mt-28">
          <div className="flex gap-20 items-center whitespace-nowrap animate-scroll">
            {Array(8)
              .fill("Own Your Tomorrow")
              .map((item) => (
                <div>
                  <p className="text-white text-[36px] mobile:text-[20px]">
                    {item}
                  </p>
                </div>
              ))}
          </div>
        </div> */}
        <div className="relative w-full h-full">
          <div className="1 w-full">
            <div className="w-full px-4 sm:px-6 md:px-12 lg:px-20 xl:px-32 flex justify-center bg-tertiary">
              <div className="w-full flex flex-col h-full">
                <div className="max-w-screen-xl w-full h-full py-7 px-4 sm:px-6 md:px-10 flex flex-col flex-wrap mx-auto">
                  <div className="flex w-full mt-10 sm:mt-20 md:mt-30 lg:mt-40 justify-between">
                    <div className="flex gap-6 sm:gap-10 md:gap-15 lg:gap-20 flex-col md:flex-row w-full">
                      <div className="w-full md:w-[35%]">
                        <img src={logo} alt="" className="w-full h-auto" />
                        <p className="text-primary text-[16px] font-poppins mt-10">
                          Discover a holistic approach to trading education at
                          Train For Success (TFS), where our renowned founder
                          Nishant Arora leads a community of over 20,000 members
                          towards financial mastery and personal development.
                        </p>
                        <p className="mt-10 sm:mt-15 md:mt-20 text-h5 font-bold text-primary">
                          <span className="text-[16px] font-poppins font-normal">
                            Support:
                          </span>
                          <br /> info@welcometotfs.com
                        </p>
                      </div>
                      <div className="w-full md:w-[15%] mt-5">
                        <p className="text-[20px] font-normal text-primary dark:text-white">
                          Company
                        </p>
                        <hr className="w-[60%] mt-5 mb-5 border-[2px] border-[#FFBB6B]" />
                        <ul className="text-primary text-body flex flex-col gap-5 font-normal">
                          {companyLinks.map((item) => (
                            <QuickLink
                              {...item}
                              handleLinkClick={handleLinkClick}
                            />
                          ))}
                        </ul>
                      </div>
                      <div className="w-full md:w-[15%] mt-5">
                        <p className="text-[20px] font-normal text-primary dark:text-white">
                          Quick Links
                        </p>
                        <hr className="w-[60%] mt-5 mb-5 border-[2px] border-[#FFBB6B]" />
                        <ul className="text-primary text-body flex flex-col gap-5 font-normal">
                          {quickLinks.map((item) => (
                            <QuickLink
                              {...item}
                              handleLinkClick={handleLinkClick}
                            />
                          ))}
                          {/* <li>
                            <p
                              className="hover:underline cursor-pointer"
                              onClick={() => setInitialPopup(true)}
                            >
                              Add Review
                            </p>
                          </li> */}
                        </ul>
                      </div>
                      <div className="w-full md:w-[35%] mt-5">
                        <div className="glassbg2 h-full rounded-[40px] gap-7 flex flex-col items-center justify-center pb-9">
                          <p className="mt-6 text-[20px] font-normal text-primary dark:text-white">
                            Social Links
                          </p>
                          {[
                            socialLinks.map((item, index) => (
                              <SocialLink key={index} {...item} />
                            )),
                          ]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="border-[#214284] border-[1px] w-full mx-auto dark:border-gray-700 opacity-20 mt-10 sm:mt-15 md:mt-20" />
                  <div className="max-w-screen-xl w-full h-full flex justify-between mt-5 pb-5 flex-wrap mx-auto">
                    <div className="flex mt-4">
                      <div className="text-primary flex gap-4 decoration-slice cursor-pointer">
                        <p
                          className="underline text-[18px]"
                          onClick={() =>
                            window.open(
                              "https://tfspublic.s3.ap-south-1.amazonaws.com/WebSite_Pages/websiterefunds.pdf",
                              "_blank"
                            )
                          }
                        >
                          Refund Policy
                        </p>{" "}
                        |{" "}
                        <p
                          className="underline text-[18px]"
                          onClick={() =>
                            window.open(
                              "https://tfspublic.s3.ap-south-1.amazonaws.com/WebSite_Pages/t%26c.html",
                              "_blank"
                            )
                          }
                        >
                          Terms and Condition
                        </p>{" "}
                        |{" "}
                        <p
                          className="underline text-[18px]"
                          onClick={() =>
                            window.open(
                              "https://tfspublic.s3.ap-south-1.amazonaws.com/WebSite_Pages/pp.html",
                              "_blank"
                            )
                          }
                        >
                          Privacy Policy
                        </p>
                      </div>
                    </div>
                    <span className="text-[18px] text-primary mt-4 sm:text-center dark:text-gray-400">
                      © 2024 TFS Pvt Ltd. All Rights Reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
