import constants from "../utils/constants";

export const statsData = [
  {
    url: "https://www.facebook.com/groups/welcometotfs",
    content: (
      <>
        <p className="text-[36px] font-lora font-[700]  text-primary">
          22,000+
        </p>
        <p className="text-[16px] font-[400] font-poppins text-primary">
          Growing FB
          <br />
          Community
        </p>
      </>
    ),
  },
  {
    url: "https://youtube.com/@welcometotfs?si=F_6irqnxSTMZe9qY",
    content: (
      <>
        <p className="text-[36px] font-lora font-[700]  text-primary">
          10,000+
        </p>
        <p className="text-[16px] font-[400] font-poppins text-primary">
          Subscribers <br />
          on youtube
        </p>
      </>
    ),
  },
  {
    url: "https://t.me/welcometotfs14",
    content: (
      <>
        <p className="text-[36px] font-lora font-[700]  text-primary">2,000+</p>
        <p className="text-[16px] font-[400] font-poppins text-primary">
          TFS <br />
          Telegram Students
        </p>
      </>
    ),
  },
];

export const ytTestimonials = [
  { url: "https://youtu.be/aeO7iUjAqQo", title: "Wonder women of TIC" },
  { url: "https://youtu.be/doFwhFt4Lms", title: "Maulik V" },
  { url: "https://youtu.be/5-uK03MHKLA", title: "Soumen &  Sagarika" },
  { url: "https://youtu.be/QpgtmHbKzhc", title: "Ramjee Singh" },
  { url: "https://youtu.be/zU7ZcGn8IzA", title: "Ashish Dongre" },
  { url: "https://youtu.be/nYqUQ3a5bic", title: "Sugapriya & Abhinav" },
];

export const services = [
  {
    title: "Integrity",
    desc: "We uphold the highest ethical standards in all aspects of our education and interactions, ensuring transparency and trustworthiness.",
    imgUrl:
      "https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/integrity.png",
  },
  {
    title: "Education",
    desc: "We are committed to providing comprehensive and practical trading education that equips our members with the knowledge, skills, and mindset necessary for long-term success.",
    imgUrl:
      "https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/educaion.png",
  },
  {
    title: "Community",
    desc: "We foster a supportive and inclusive community where members uplift and inspire each other, sharing insights, experiences, and encouragement on the journey to mastery.",
    imgUrl:
      "https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/community.png",
  },
  {
    title: "Empowerment",
    desc: "We empower individuals to take control of their financial futures through education, mentorship, and a supportive environment that encourages risk-aware decision-making.",
    imgUrl:
      "https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/empowerment.png",
  },
  {
    title: "Improvement",
    desc: "We embrace a growth mindset and are dedicated to continuously evolving our programs, resources, and support systems to meet the evolving needs of our members.",
    imgUrl:
      "https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/improvement.png",
  },
  {
    title: "Impact",
    desc: "We are driven by the desire to make a positive impact on the lives of our members, helping them achieve their trading goals and unlock their full potential in both the financial markets.",
    imgUrl:
      "https://tfspublic.s3.ap-south-1.amazonaws.com/images/About/impact.png",
  },
];

export const slides = [
  {
    heading: "Why choose us",
    title: "Elevate Your Trading Journey with TFS and TIC",
    content:
      "Choose Train Choose Train For Success (TFS) and The Inner Circle (TIC) for unparalleled trading education and personal development. Led by visionary Nishant Arora, TFS boasts a Facebook group with 20,000+ members, renowned for its sincere approach to guiding traders on their journey. TIC, an exclusive offshoot, offers a premium experience with real-time mentorship and a thriving community of over 2,000 students. With TFS and TIC, success isn't just taught; it's lived and shared, making us the ultimate choice for those seeking to thrive in the world of trading and beyond.",
  },
  {
    heading: "Why choose ussssssssssssss",
    title: "Elevate Your Trading Journey with TFS and TIC",
    content:
      "Choose Train Choose Train For Success (TFS) and The Inner Circle (TIC) for unparalleled trading education and personal development. Led by visionary Nishant Arora, TFS boasts a Facebook group with 20,000+ members, renowned for its sincere approach to guiding traders on their journey. TIC, an exclusive offshoot, offers a premium experience with real-time mentorship and a thriving community of over 2,000 students. With TFS and TIC, success isn't just taught; it's lived and shared, making us the ultimate choice for those seeking to thrive in the world of trading and beyond.",
  },
  {
    heading: "Why chooseeeeeeeeeeeeeee us",
    title: "Elevate Your Trading Journey with TFS and TIC",
    content:
      "Choose Train Choose Train For Success (TFS) and The Inner Circle (TIC) for unparalleled trading education and personal development. Led by visionary Nishant Arora, TFS boasts a Facebook group with 20,000+ members, renowned for its sincere approach to guiding traders on their journey. TIC, an exclusive offshoot, offers a premium experience with real-time mentorship and a thriving community of over 2,000 students. With TFS and TIC, success isn't just taught; it's lived and shared, making us the ultimate choice for those seeking to thrive in the world of trading and beyond.",
  },
  {
    heading: "Why choose ussssssssssssss",
    title: "Elevate Your Trading Journey with TFS and TIC",
    content:
      "Choose Train Choose Train For Success (TFS) and The Inner Circle (TIC) for unparalleled trading education and personal development. Led by visionary Nishant Arora, TFS boasts a Facebook group with 20,000+ members, renowned for its sincere approach to guiding traders on their journey. TIC, an exclusive offshoot, offers a premium experience with real-time mentorship and a thriving community of over 2,000 students. With TFS and TIC, success isn't just taught; it's lived and shared, making us the ultimate choice for those seeking to thrive in the world of trading and beyond.",
  },
  {
    heading: "Why chooseeeeeeeeeeeeeee us",
    title: "Elevate Your Trading Journey with TFS and TIC",
    content:
      "Choose Train Choose Train For Success (TFS) and The Inner Circle (TIC) for unparalleled trading education and personal development. Led by visionary Nishant Arora, TFS boasts a Facebook group with 20,000+ members, renowned for its sincere approach to guiding traders on their journey. TIC, an exclusive offshoot, offers a premium experience with real-time mentorship and a thriving community of over 2,000 students. With TFS and TIC, success isn't just taught; it's lived and shared, making us the ultimate choice for those seeking to thrive in the world of trading and beyond.",
  },
];

export const companyLinks = [
  { text: "Home", to: "/home" },
  { text: "About us", to: "/aboutus" },
  // { text: "Pricing", to: "/pricing" },
  // { text: "Sign In", to: "/login" },
];

export const quickLinks = [
  { text: " Contact Us", to: "mailto:info@welcometotfs.com" },
  { text: "Images", to: "/resources" },
  { text: "Blogs", to: "/resources" },
];

export const socialLinks = [
  {
    text: "Facebook",
    url: constants.SOCIAL_MEDIA_LINKS.FACEBOOK,
  },
  {
    text: "Instagram",
    url: constants.SOCIAL_MEDIA_LINKS.INSTAGRAM,
  },
  {
    text: "YouTube",
    url: constants.SOCIAL_MEDIA_LINKS.YOUTUBE,
  },
  {
    text: "Telegram",
    url: constants.SOCIAL_MEDIA_LINKS.TELEGRAM,
  },
];
