import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/logo.svg";
import liImg from "../Images/li copy.svg"; // replace with your actual image path
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlans, registerToEvent, updateStatus } from '../store/actions/website.action';
import { enterBasicDetails } from "../store/actions/admin.action";
const GetPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("monthly");
  const { planList } = useSelector((state) => state?.web);
  console.log(planList)
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const { planList } = useSelector((state) => state?.web);
  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  //   if (tab === "yearly") {
  //     setPlanList([{ fees: 20000 }]);
  //   } else {
  //     setPlanList([{ fees: 15000 }]);
  //   }
  // };
  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);
  const openPayModal = (response, id) => {
    const options = {
      key: "rzp_live_POZ84Rf3NXSoEH",
      amount: response.data.amount,
      order_id: id,
      name: "TFS",
      handler: function (response) {
        const responseData = JSON.parse(localStorage.getItem("responseData"));
        if (responseData) {
          const payload = {
            "otpReference": responseData.referenceNo,
            "grantType": "userOnboardingStep4"
          }
          dispatch(enterBasicDetails(payload, (res) => {
            if (res.status === 200) {
              console.log("Payment successful!");
              navigate("/login")
            }
          }));
        }
      },
      modal: {
        ondismiss: function (res) {
          dispatch(updateStatus(id, (res) => {
            if (res.status === 200) {
              console.log("Payment successful!");
            }
          }));
        }
      },
      theme: {
        color: "#214284",
        hide_topbar: true,
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const updateOrderIdInSession = (id) => {
    sessionStorage.setItem("orderid", id);
    console.log("id---", id);
  };

  const handlepayment = (id) => {
    console.log("id---", id)
    const responseData = JSON.parse(localStorage.getItem("responseData"));
    if (responseData) {
      const payload = {
        "planId": id,
        "otpReference": responseData.referenceNo,
        "grantType": "userOnboardingStep3"
      };
      dispatch(enterBasicDetails(payload, (response) => {
        if (response?.status === 200) {
          openPayModal(response, response?.data?.id);
          updateOrderIdInSession(response?.data?.id);
        } else if (response?.response?.status === 400) {
          const existingOrderId = sessionStorage.getItem("orderid");
          if (existingOrderId) {
            dispatch(updateStatus(existingOrderId, (updateResponse) => {
              if (updateResponse?.status === 200) {
                handlepayment();
              }
            }));
          }
        }
      }));
    }
  };
  return (
    <div className="w-full min-h-screen bg-[#FFFAED] relative">

      <section className="text-center py-8">
        <div className="container mx-auto">
          <h1 className="text-4xl font-semibold text-[#214284] font-lora">
            Welcome To TFS
          </h1>
        </div>
      </section>

      <div className="flex justify-center items-center rounded-[40px]  bg-[#FFFAED] mobile:rounded-none">
        <div className="bg-white rounded-[40px] shadow-md w-full  mobile:rounded-none  laptop:w-[1000px] mb-7">
          <p
            className="text-center font-lora font-bold text-2xl mt-5 mb-5"
            style={{ color: "rgba(255, 187, 107, 1)" }}
          >
            Select Badge
          </p>
          <hr className="border-t-2 opacity-16 mb-4" />

          <div className="flex justify-center py-8">
            <div className="flex justify-center rounded-[36px] border bg-[#FFF] p-0.5">
              <button
                className={`px-4 py-2 font-[600] font-poppins text-primary rounded-[36px] ${activeTab === "monthly" ? "bg-secondary" : "bg-[#FFF]"}`}
                onClick={() => setActiveTab("monthly")}
              >
                Half Yearly
              </button>
              <button
                className={`px-4 py-2 font-poppins font-[600] text-primary rounded-[36px] ${activeTab === "yearly" ? "bg-secondary text-[#FFF]" : "bg-[#FFF]"}`}
                onClick={() => setActiveTab("yearly")}
              >
                Yearly
              </button>
            </div>
          </div>
          {activeTab === "monthly" && (
            <>
              <div className="flex flex-col laptop:flex-row laptop:justify-center laptop:items-center text-center items-center mobile:px-4">
                <div className="flex flex-col items-center laptop:w-[40%] laptop:items-center laptop:border-r-4 laptop:pr-4 laptop:ml-5 mobile:w-full">
                  <p className="text-h6 text-[#214284] font-poppins font-[600]">Basic</p>
                  <p className="text-h1 font-[600] mt-5 font-lora text-[#214284]">
                    INR <br />{planList?.[0]?.fees}
                  </p>
                  <hr className="border opacity-12 justify-center items-center text-center mt-7 mb-7 w-2/3 laptop:hidden" />
                </div>
                <div className="w-full flex flex-col items-start laptop:items-center laptop:w-[40%] p-4">
                  <ul className="text-[#214284] font-poppins font-bold text-[16px] flex flex-col gap-5">
                    <li className="flex items-center space-x-3">
                      <img src={liImg} alt="" /> <span>Inclusive of GST</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <img src={liImg} alt="" /> <span>Free Platform Access</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <img src={liImg} alt="" /> <span>24/7 Customer Support</span>
                    </li>
                  </ul>
                </div>
              </div>

              <hr className="border opacity-16 mt-6" />

              <div className="flex mobile:flex-col laptop:flex-row mobile:space-y-0 ">
                <div className="w-full md:w-1/2 flex justify-center">
                  <p className="w-full md:w-auto mobile:text-center item-center justify-center " onClick={() => handlepayment(planList?.[0]?.planId)}>
                    <Button label="MAKE PAYMENT" type="submit" variant="solid"  />
                  </p>

                </div>
                <div className="w-full md:w-1/2 flex justify-center">
                  <Link to="/" className="w-full md:w-auto mobile:text-center item-center justify-center font-primary">
                    <Button label="MAYBE LATER" type="submit" variant="outline" className="" />
                  </Link>

                </div>
              </div>
            </>
          )}
          {activeTab === "yearly" && (
            <>
              <div className="flex flex-col laptop:flex-row laptop:justify-center laptop:items-center text-center items-center mobile:px-4">
                <div className="flex flex-col items-center laptop:w-[40%] laptop:items-center laptop:border-r-4 laptop:pr-4 laptop:ml-5 mobile:w-full">
                  <p className="text-h6 text-[#214284] font-poppins font-[600]">Basic</p>
                  <p className="text-h1 font-[600] mt-5 font-lora text-[#214284]">
                    INR <br />{planList?.[1]?.fees}
                  </p>
                  <hr className="border opacity-12 justify-center items-center text-center mt-7 mb-7 w-2/3 laptop:hidden" />
                </div>
                <div className="w-full flex flex-col items-start laptop:items-center laptop:w-[40%] p-4">
                  <ul className="text-[#214284] font-poppins font-bold text-[16px] flex flex-col gap-5">
                    <li className="flex items-center space-x-3">
                      <img src={liImg} alt="" /> <span>Inclusive of GST</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <img src={liImg} alt="" /> <span>Free Platform Access</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <img src={liImg} alt="" /> <span>24/7 Customer Support</span>
                    </li>
                  </ul>
                </div>
              </div>

              <hr className="border opacity-16 mt-6" />

              <div className="flex mobile:flex-col laptop:flex-row mobile:space-y-0 ">
                <div className="w-full md:w-1/2 flex justify-center">
                  <p className="w-full md:w-auto mobile:text-center item-center justify-center" onClick={() => handlepayment(planList?.[2]?.planId)}>
                    <Button label="MAKE PAYMENT" type="submit" variant="solid"  />
                  </p>

                </div>
                <div className="w-full md:w-1/2 flex justify-center">
                  <Link to="/login" className="w-full md:w-auto mobile:text-center item-center justify-center font-primary">
                    <Button label="MAYBE LATER" type="submit" variant="outline" className="" />
                  </Link>

                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetPayment;
