import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";

const initialValues = {
  title: "",
  message: "",
  photo: null,
};

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Message is required"),
  photo: Yup.mixed().required("Photo is required"),
});

const CreateTestimonial = ({ setInitialPopup }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      // Step 1: Call API to get signed URL
      const signedUrlResponse = await axios.get(
        `https://rudf4zn65l.execute-api.ap-south-1.amazonaws.com/dev/getSignedUrl?mediaType=testimonial&fileName=${values?.photo?.name}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (signedUrlResponse.status === 200) {
        const signedUrl = signedUrlResponse.data.data.s3SignedUrl;
        const imageUrl = signedUrl.split("?")[0]; // Use the base URL without query parameters

        // Step 2: Upload the photo to the signed URL
        const photo = values.photo;
        await axios.put(signedUrl, photo, {
          headers: {
            "Content-Type": photo.type,
          },
        });

        // Step 3: Call API to add testimonial with the image URL and details
        const addTestimonialResponse = await axios.post(
          "https://rudf4zn65l.execute-api.ap-south-1.amazonaws.com/dev/managerUser/student/submitTestimonial",
          {
            title: values.title,
            message: values.message,
            attachments: [signedUrl],
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        );

        // Handle response if necessary
        console.log("Testimonial added:", addTestimonialResponse.data);

        setSubmitting(false);
        setInitialPopup(false);
      }
    } catch (error) {
      console.error("Error adding testimonial", error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <div
        className="overflow-y-auto overflow-x-hidden fixed z-[999] bg-black bg-opacity-[0.6] justify-center h-full flex items-center w-full inset-0 max-h-full font-raleway"
        onClick={(e) => {
          e.currentTarget === e.target && setInitialPopup(false);
        }}
      >
        <div className="justify-center items-center gap-5 flex rounded-[20px] flex-col p-5 w-[45%] tablet:w-[90%] mobile:w-[90%] text-center max-h-full bg-white opacity-100 inset-5">
          <div className="p-4 space-y-4 gap-0 flex mobile:flex-col w-full justify-center">
            <div className="w-full">
              <p className="text-primary text-2xl font-bold capitalize">
                We always try to be as close to you as possible
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Form>
                    <div className="mt-5">
                      <Field
                        type="text"
                        name="title"
                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full py-[17px]"
                        placeholder="Enter Title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mt-5">
                      <Field
                        as="textarea"
                        name="message"
                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full py-[17px]"
                        placeholder="Enter Message"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mt-5">
                      <input
                        type="file"
                        name="photo"
                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full"
                        onChange={(event) => {
                          setFieldValue("photo", event.currentTarget.files[0]);
                        }}
                      />
                      <ErrorMessage
                        name="photo"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="mt-5 font-semibold cursor-pointer disabled:cursor-wait rounded-[33px] text-center text-[20px] font-poppins text-white bg-secondary w-full py-[17px]"
                    >
                      Submit Testimonial
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTestimonial;
