import React, { useState } from "react";
import KYCFormComponent from "./KycConfirmation";
import KycVerification from "./KycVerification";
import KycDeclaration from "./KycDeclaration";
import { useDispatch } from "react-redux";
import { submitKycinfo } from "../store/actions/admin.action";
import toast from "react-hot-toast";

const CheckMarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

const StepIndicator = ({ profileData }) => {
  // ---------------------------------------- All Variables ----------------------------------------

  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const profileInfo = JSON.parse(localStorage?.getItem("userInfo"));
  const [formData, setFormData] = useState({ step1: {}, step2: {}, step3: {} });

  // ---------------------------------------- Function for handle next button --------------------------------

  const handleNext = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      [`step${currentStep}`]: data,
    }));
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // ---------------------------------------- Function for handle back button --------------------------------

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // ----------------------------------------- Function for get current date in dd-mm-yyyy format --------------------------------

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // ----------------------------------------- Function for handle submit button --------------------------------
  const handleSubmit = (data) => {
    const finalData = {
      ...formData,
      step3: data,
    };
    const payload = {
      uniqueId: profileInfo?.uniqueId,
      emailAddress: finalData?.step1?.email,
      mobileNo: finalData?.step1?.mobileNumber,
      firstName: finalData?.step1?.firstName,
      lastName: finalData?.step1?.lastName,
      idProofLink: finalData?.step2?.idProofs,
      profilePhotoLink: finalData?.step2?.profilePhoto,
      dob: getCurrentDate(),
      fatherOrSpouseName: finalData?.step1?.spouseName,
      permanentAddress: finalData?.step1?.address,
      permanentCountry: finalData?.step1?.country,
      permanentState: finalData?.step1?.state,
      permanentCity: finalData?.step1?.city,
      permanentPinCode: finalData?.step1?.pincode,
      currentAddress: finalData?.step1?.currentAddress,
      currentCountry: finalData?.step1?.currentCountry,
      currentState: finalData?.step1?.currentState,
      currentCity: finalData?.step1?.currentCity,
      currentPinCode: finalData?.step1?.currentPincode,
      telegramUserName: finalData?.step1?.telegramUsername,
      referralType: finalData?.step1?.referredBy || "Self",
      referralName: finalData?.step1?.referredPersonName || "Self",
      runMembershipOrEducationalService: finalData?.step1?.inFinancialSector,
      gstBillRequired: finalData?.step1?.gstInvoice,
      ...(finalData?.step1?.gstInvoice === "yes" && {
        gstNo: finalData?.step1?.gstNumber,
      }),
    };
    dispatch(
      submitKycinfo(payload, (response) => {
        if (response?.status === 200 || 201) {
          toast.success("kyc submitted successfully!");
        }
      })
    );
  };

  return (
    <div className="w-full bg-white shadow-md rounded-[40px] h-full pb-5">
      <div className="flex justify-center w-full pt-10 items-center space-x-2 p-4 laptop:items-center laptop:justify-center">
        <div
          className={`${
            currentStep >= 1 ? "ring-2 ring-primary rounded-full p-1" : ""
          }`}
        >
          <div
            className={`w-10 h-10 flex items-center justify-center font-bold  ${
              currentStep >= 1
                ? "text-primary bg-secondary "
                : "text-primary bg-tertiary"
            } rounded-full`}
          >
            {currentStep > 1 ? <CheckMarkIcon /> : "1"}
          </div>
        </div>
        <hr className="border-t-2 w-32" />
        <div
          className={`${
            currentStep >= 2 ? "ring-2 ring-primary rounded-full p-1" : ""
          }`}
        >
          <div
            className={`w-10 h-10 flex items-center justify-center font-bold  ${
              currentStep >= 2
                ? "text-primary bg-secondary "
                : "text-secondary bg-tertiary"
            } rounded-full`}
          >
            {currentStep > 2 ? <CheckMarkIcon /> : "2"}
          </div>
        </div>
        <hr className="border-t-2 w-32" />
        <div
          className={`${
            currentStep >= 3 ? "ring-2 ring-primary rounded-full p-1" : ""
          }`}
        >
          <div
            className={`w-10 h-10 flex items-center justify-center font-bold  ${
              currentStep >= 3
                ? "text-primary bg-secondary "
                : "text-secondary bg-tertiary"
            } rounded-full`}
          >
            {currentStep > 3 ? <CheckMarkIcon /> : "3"}
          </div>
        </div>
      </div>
      {currentStep === 1 && (
        <KYCFormComponent onNext={handleNext} profileData={profileData} />
      )}
      {currentStep === 2 && (
        <KycVerification onNext={handleNext} onBack={handleBack} />
      )}
      {currentStep === 3 && (
        <KycDeclaration onSubmit={handleSubmit} onBack={handleBack} />
      )}
    </div>
  );
};

export default StepIndicator;
