import React, { useEffect, useState } from "react";
import profileBg from "../Images/profileBg.png";
import videoImg from "../Images/video.png";
import Pagination from "../components/Resources/Pagination";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getProfileInfo } from "../store/actions/auth.action";
import { getAllEvents } from "../store/actions/website.action";

const InsieAdmin = () => {
  const [openTab, setOpenTab] = useState(1);
  const dispatch = useDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  const data = JSON?.parse(localStorage?.getItem("userInfo"));
  const { profileData } = useSelector((state) => state?.auth);
  const { eventdetails, loader } = useSelector((state) => state?.web);

  useEffect(() => {
    dispatch(getProfileInfo());
  }, [dispatch]);
  const [currentIndexes, setCurrentIndexes] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const navigateToDetailPage = (index) => {
    const blogId = index;
    const url = `/events/${blogId}`;
    navigate(url);
  };

  useEffect(() => {
    const payload = {
      pageNo: 0,
      perPageResults: 0,
    };
    dispatch(getAllEvents(payload));
  }, [dispatch]);

  return (
    <>
      <div className="bg-tertiary bg-no-repeat h-full -mt-5 ">
        <div className="flex flex-col justify-center items-center h-full w-full px-48 mobile:px-0">
          <div className="bg-white mobile:rounded-none w-full mt-36 rounded-[40px] h-[120px] mobile:h-auto">
            <div className="flex justify-between h-full py-4 mobile:flex-col mobile:gap-7">
              <div className="flex gap-3 items-center pl-4 ">
                {loader ? (
                  <Skeleton circle={true} height={88} width={88} />
                ) : (
                  <img
                    src={profileBg}
                    alt=""
                    className="rounded-full h-[88px] w-[88px]"
                  />
                )}
                <div>
                  <p className="text-xl text-primary font-poppins font-semibold">
                    {loader ? (
                      <Skeleton width={200} />
                    ) : (
                      `${profileData?.firstName} ${profileData?.lastName}` ||
                      `${data?.firstName} ${data?.lastName}`
                    )}
                  </p>
                  <p className="text-base text-primary font-poppins">
                    {loader ? (
                      <Skeleton width={300} />
                    ) : (
                      `${profileData?.emailAddress} | ${profileData?.mobileNumber}` ||
                      `${data?.emailAddress}`
                    )}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 items-center mobile:flex-col">
                <div className="w-full flex gap-4 h-full pr-4 mobile:px-3 mobile:items-center mobile:flex-col">
                  <div className="bg-tertiary p-3 px-5 flex flex-col justify-center mobile:justify-between mobile:items-center mobile:flex-row h-full rounded-[20px] mobile:rounded-[10px] w-full mobile:mb-4">
                    <p className="text-sm mobile:text-lg font-poppins text-primary mobile:justify-start mobile:text-center font-normal">
                      Unique ID
                    </p>
                    <p className="text-sm font-poppins mobile:text-lg text-primary font-bold text-center mt-2">
                      {loader ? (
                        <Skeleton width={100} />
                      ) : (
                        profileData?.uniqueId
                      )}
                    </p>
                  </div>
                  <div className="bg-tertiary mobile:justify-between mobile:items-center p-3 px-5 flex flex-col justify-center mobile:flex-row mobile:rounded-[10px] text-center h-full rounded-[20px] w-full mobile:mb-4">
                    <p className="text-sm mobile:text-lg font-poppins text-primary font-normal">
                      Batch No
                    </p>
                    <p className="text-sm mobile:text-lg font-poppins text-primary font-bold text-center mt-2">
                      {loader ? <Skeleton width={100} /> : profileData?.batchNo}
                    </p>
                  </div>
                  <div className="bg-tertiary p-3 mobile:justify-between mobile:items-center flex flex-col justify-center mobile:flex-row  mobile:rounded-[10px] rounded-[20px] w-full">
                    <p className="text-sm  font-poppins mobile:hidden text-primary font-normal text-center">
                      Subscription
                      <br />
                      End on
                    </p>
                    <p className="text-sm  font-poppins mobile:text-lg laptop:hidden text-primary font-normal text-center">
                      Subscription End on
                    </p>
                    <p className="text-sm font-poppins mobile:text-lg text-primary font-bold text-center mt-2">
                      {loader ? (
                        <Skeleton width={100} />
                      ) : (
                        profileData?.subscriptionEndDate
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-10 mobile:flex-row">
            <div className="text-sm font-medium text-center text-gray-500  mobile:flex-row border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
              <ul
                className="flex flex-wrap gap-10 mobile:flex-row -mb-px"
                role="tablist"
              >
                <li className="me-2">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#videos"
                    role="tablist"
                    className={
                      "inline-block p-4 text-2xl text-primary rounded-t-lg  " +
                      (openTab === 1
                        ? " border-b-[3px] border-primary font-bold"
                        : " font-normal")
                    }
                  >
                    Active Events{" "}
                  </a>
                </li>
                <li className="me-2">
                  <a
                    className={
                      "inline-block p-4 rounded-t-lg text-2xl text-primary " +
                      (openTab === 2
                        ? " border-b-[3px] border-primary font-bold text-primary "
                        : "font-normal ")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#images"
                    role="tablist"
                  >
                    Scheduled
                  </a>
                </li>
              </ul>
            </div>
            <div className="relative flex flex-col min-w-0 mt-10 mobile:mt-10 w-full mb-6">
              <div className="flex-auto flex justify-center">
                <div className="tab-content tab-space w-[100%] flex justify-center flex-col">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="videos"
                  >
                    <div className="grid grid-cols-3 mobile:grid-cols-1 gap-10 w-full">
                      {loader
                        ? Array.from({ length: 6 }).map((_, i) => (
                            <div
                              key={i}
                              className="rounded-[32px] border-[1px] glassbg2 border-white px-3 py-3"
                            >
                              <Skeleton height={200} />
                              <Skeleton height={30} className="mt-3" />
                              <Skeleton height={20} className="mt-3 mb-3" />
                              <Skeleton height={50} className="mt-3" />
                            </div>
                          ))
                        : eventdetails?.eventList?.map((event, i) => (
                            <div
                              key={i}
                              className="rounded-[32px] border-[1px] glassbg2 border-white px-3 py-3"
                            >
                              <div className="">
                                <div className="relative">
                                  <img
                                    src={event?.attachments?.[0]}
                                    alt=""
                                    className="w-full h-[240px] object-cover rounded-[20px]"
                                  />
                                </div>
                                <p className="text-h5 mt-3 text-primary font-bold">
                                  {event?.eventName}
                                </p>
                                <p className="text-body font-poppins font-medium text-primary mt-3 mb-3">
                                  {event?.eventDate} | {event?.eventStartTime} -{" "}
                                  {event?.eventEndTime}
                                </p>
                                <div className="w-full gap-4 flex items-center h-full">
                                  <div className="bg-primary rounded-[35px] py-3 items-center w-[80%] text-white justify-center flex">
                                    <p
                                      className="font-poppins font-semibold text-xl cursor-pointer"
                                      onClick={() =>
                                        navigateToDetailPage(event?.eventId)
                                      }
                                    >
                                      Reserve Your Spot
                                    </p>
                                  </div>
                                  <div className="w-[50px] h-[50px] bg-white rounded-full flex justify-center items-center">
                                    <svg
                                      width="12"
                                      height="19"
                                      viewBox="0 0 12 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 9.49997C12 9.15945 11.8559 8.81897 11.5684 8.55936L2.51663 0.389769C1.94083 -0.129923 1.00725 -0.129923 0.431681 0.389769C-0.143894 0.909252 -0.143894 1.75168 0.431681 2.27141L8.44122 9.49997L0.43196 16.7286C-0.143614 17.2483 -0.143614 18.0906 0.43196 18.61C1.00753 19.13 1.94111 19.13 2.51691 18.61L11.5687 10.4406C11.8563 10.1808 12 9.84036 12 9.49997Z"
                                        fill="#214284"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={Math.ceil(
                        eventdetails?.eventList?.length / itemsPerPage
                      )}
                      paginate={paginate}
                    />
                  </div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="images"
                  >
                    {/* <div className='grid grid-cols-3 gap-10 w-full'>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(
                                                (_, index) => (
                                                    <div
                                                        key={index}
                                                        className="rounded-[32px] border-[1px] glassbg2 border-white px-3 transparent py-3"
                                                        onMouseEnter={() => handleMouseEnter(index)}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className=''>
                                                            <div className="relative">
                                                                <img src={videoImg} alt='' className="w-full h-auto" />
                                                            </div>
                                                            <p className='text-h5 mt-3 text-primary font-bold'>Nishant Arora TFS- Online Trading Summit Interview</p>
                                                            <p className='text-body font-poppins font-medium text-primary mt-3 mb-3'>Apr 23, 2024 | 2:30pm-4:00pm</p>
                                                            <div className='w-full gap-4 flex items-center h-full'>
                                                                <div className='bg-secondary rounded-[35px] py-3 items-center w-[80%] text-primary justify-center flex cursor-pointer'>
                                                                    <p className={"font-poppins font-semibold text-x"} onClick={() => navigateToDetailPage(index)}>Reserve Your Spot</p>
                                                                </div>
                                                                <div className='w-[50px] h-[50px] cursor-pointer bg-white rounded-full flex justify-center items-center'>
                                                                    <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 9.49997C12 9.15945 11.8559 8.81897 11.5684 8.55936L2.51663 0.389769C1.94083 -0.129923 1.00725 -0.129923 0.431681 0.389769C-0.143894 0.909252 -0.143894 1.75168 0.431681 2.27141L8.44122 9.49997L0.43196 16.7286C-0.143614 17.2483 -0.143614 18.0906 0.43196 18.61C1.00753 19.13 1.94111 19.13 2.51691 18.61L11.5687 10.4406C11.8563 10.1808 12 9.84036 12 9.49997Z" fill="#214284" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div> */}
                    {/* <Pagination /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsieAdmin;
