import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import prevBtn from "../../Images/prev.svg";
import nextBtn from "../../Images/next.svg";
import tube from "../../Images/tube.svg";
import { getAllTestimonials } from "../../store/actions/website.action";
import { ytTestimonials } from "../../data/appData";
import { getEmbedUrl } from "../../utils/helper";

const Testimonials = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllTestimonials({
        userId: "",
        pageNo: 1,
        perPageResults: 100,
      })
    );
  }, [dispatch]);

  const handlePrev = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? ytTestimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === ytTestimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="w-full h-full min-h-[650px] mobile:h-full mobile:bg-none relative bg-cover bg-no-repeat bg-body-bg mt-20 justify-center flex flex-col text-center items-center">
      <div className="relative">
        <img src={tube} alt="" className="absolute mt-8 -ml-28" />
        <p className="text-[56px] mobile:text-[36px] z-10 text-primary font-[700] leading-tight font-lora mt-8 relative">
          TICian Speaks!
        </p>
      </div>
      <div className="w-[57%] mobile:w-[90%] relative mt-12">
        <div className="rounded-[20px] bg-tertiary shadow-lg p-5 min-h-[400px] flex justify-center items-center">
          <iframe
            width="100%"
            height="350"
            src={getEmbedUrl(ytTestimonials[currentTestimonialIndex].url)}
            title={`YouTube video ${currentTestimonialIndex + 1}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="rounded-[10px] border border-gray-300"
          />
        </div>
      </div>
      <div className="flex gap-4 mt-7 mobile:px-4">
        <img
          src={prevBtn}
          alt="Previous"
          className="cursor-pointer"
          onClick={handlePrev}
        />
        <button className="bg-secondary text-primary text-[20px] mobile:px-10 px-[70px] rounded-[60px] font-[700]">
          {ytTestimonials[currentTestimonialIndex].title}
        </button>
        <img
          src={nextBtn}
          alt="Next"
          className="cursor-pointer"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Testimonials;
