import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import photoimg from "../Images/formphoto.jpg";
import { useDispatch } from "react-redux";
import { subscribeForm } from "../store/actions/website.action";
import toast from "react-hot-toast";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const FormPopup2 = ({ closeModal }) => {
  const initialValues = {
    name: "",
    emailId: "",
  };

  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(
      subscribeForm(values, (response) => {
        if (response) {
          if (response.status === 200) {
            closeModal();
            toast.success("Email Subscribe Successfully!");
            sessionStorage.setItem("hasModalBeenShown", "true");
          } else if (response?.response?.status === 400) {
            closeModal();
          }
        }
        setSubmitting(false);
      })
    );
  };

  return (
    <div
      className="overflow-y-auto overflow-x-hidden fixed z-[999] bg-black bg-opacity-[0.6] justify-center h-full flex items-center w-full inset-0 max-h-full font-raleway"
      onClick={(e) => {
        e.currentTarget === e.target && closeModal();
      }}
    >
      <div className="justify-center items-center gap-5 flex rounded-[60px] flex-col p-5 w-[45%] tablet:w-[90%] mobile:w-[90%] text-center max-h-full bg-white opacity-100 inset-5">
        <div className="p-4 space-y-4 gap-0 flex mobile:flex-col w-full justify-center">
          <div className="w-[50%] mobile:w-full mobile:flex mobile:justify-center">
            <img src={photoimg} alt="Form visual" className="mobile:w-[50%]" />
          </div>
          <div className="w-[50%] mobile:w-full">
            <p className="text-primary text-2xl font-bold capitalize">
              Get Access To All The Updates
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <>
                  {isSubmitting && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
                      <div className="loader border rounded-full border-white"></div>
                    </div>
                  )}
                  <Form>
                    <div className="mt-5">
                      <Field
                        type="text"
                        name="name"
                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full py-[17px]"
                        placeholder="Enter Your Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="mt-5">
                      <Field
                        type="email"
                        name="emailId"
                        className="border border-[#556990] rounded-[33px] text-center text-[14px] font-poppins text-[#556990] bg-[#f4f8ff] w-full py-[17px]"
                        placeholder="Enter Your Email"
                      />
                      <ErrorMessage
                        name="emailId"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <button
                      type="submit"
                      className="mt-5 font-semibold cursor-pointer disabled:cursor-wait rounded-[33px] text-center text-[20px] font-poppins text-white bg-secondary w-full py-[17px]"
                      disabled={isSubmitting}
                    >
                      Subscribe Now
                    </button>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPopup2;
