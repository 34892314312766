import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "./Button";
import * as yup from "yup";
import locations from "../components/Location.json";

const KYCFormComponent = ({ onNext, profileData }) => {
  // --------------------------------- All Variables --------------------------------

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [permanentStates, setPermanentStates] = useState([]);
  const [permanentCities, setPermanentCities] = useState([]);

  // ---------------------------------- Validation Schema  --------------------------------

  const schema = yup.object().shape({
    dob: yup.string().required("Date of Birth is required"),
    spouseName: yup.string().required("Father/Spouse Name is required"),
    address: yup
      .string()
      .min(5, "Address is too short")
      .max(100, "Address is too long")
      .required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    pincode: yup.string().required("Pincode is required"),
    addressSame: yup.string().required("This field is required"),
    telegramUsername: yup.string().required("Username is required"),
    inFinancialSector: yup.string().required("This field is required"),
    gstInvoice: yup.string().required("Please select an option"),
    currentAddress: yup.string().when("addressSame", {
      is: (value) => value === "no",
      then: () =>
        yup
          .string()
          .required("Current Address is required if 'No, Other' is selected"),
    }),
    currentCountry: yup.string().when("addressSame", {
      is: (value) => value === "no",
      then: () =>
        yup
          .string()
          .required("Current Country is required if 'No, Other' is selected"),
    }),
    currentState: yup.string().when("addressSame", {
      is: (value) => value === "no",
      then: () =>
        yup
          .string()
          .required("Current State is required if 'No, Other' is selected"),
    }),
    currentCity: yup.string().when("addressSame", {
      is: (value) => value === "no",
      then: () =>
        yup
          .string()
          .required("Current City is required if 'No, Other' is selected"),
    }),
    companyName: yup.string().when("gstInvoice", {
      is: (value) => value === "yes",
      then: () =>
        yup
          .string()
          .required("Company Name is required if GST invoice is needed"),
    }),
    companyAddress: yup.string().when("gstInvoice", {
      is: (value) => value === "yes",
      then: () =>
        yup
          .string()
          .required("Company Address is required if GST invoice is needed"),
    }),
    gstNumber: yup.string().when("gstInvoice", {
      is: (value) => value === "yes",
      then: () =>
        yup
          .string()
          .required("GST Number is required if GST invoice is needed"),
    }),
  });
  const prevData = localStorage?.getItem("formone");

  // Parse the data if it exists, otherwise set it to an empty object or null
  const parsedData = prevData ? JSON.parse(prevData) : null;
  // ---------------------------------- Initial Values --------------------------------

  const initialValues = {
    firstName: profileData?.firstName,
    lastName: profileData?.lastName,
    email: profileData?.emailAddress,
    mobileNumber: profileData?.mobileNumber,
    dob: "" || parsedData?.dob,
    spouseName: "" || parsedData?.spouseName,
    address: "" || parsedData?.address,
    country: "" || parsedData?.country,
    state: "" || parsedData?.state,
    city: "" || parsedData?.city,
    pincode: "" || parsedData?.pincode,
    addressSame: "yes" || parsedData?.addressSame,
    telegramUsername: "" || parsedData?.telegramUsername,
    referredBy: "" || parsedData?.referredBy,
    referredPersonName: "" || parsedData?.referredPersonName,
    inFinancialSector: "no" || parsedData?.inFinancialSector,
    gstInvoice: "no" || parsedData?.gstInvoice,
    companyName: "" || parsedData?.companyName,
    companyAddress: "" || parsedData?.companyAddress,
    gstNumber: "" || parsedData?.gstNumber,
    currentAddress: "" || parsedData?.currentAddress,
    currentCountry: "" || parsedData?.currentCountry,
    currentState: "" || parsedData?.currentState,
    currentCity: "" || parsedData?.currentCity,
    currentPincode: "" || parsedData?.currentPincode,
  };

  // ---------------------------------- handle Country change function  --------------------------------

  const handleCountryChange = (e, setFieldValue) => {
    const selectedCountry = locations.countries.find(
      (country) => country.name === e.target.value
    );
    setStates(selectedCountry ? selectedCountry.states : []);
    setCities([]);
    setFieldValue("currentCountry", e.target.value);
    setFieldValue("currentState", "");
    setFieldValue("currentCity", "");
  };

  // ------------------------------------ handle State Change functions --------------------------------

  const handleStateChange = (e, setFieldValue) => {
    const selectedState = states.find((state) => state.name === e.target.value);
    setCities(selectedState ? selectedState.cities : []);
    setFieldValue("currentState", e.target.value);
    setFieldValue("currentCity", "");
  };

  // ------------------------------------ handle Permanent Country Change functions --------------------------------

  const handlePermanentCountryChange = (e, setFieldValue) => {
    const selectedCountry = locations.countries.find(
      (country) => country.name === e.target.value
    );
    setPermanentStates(selectedCountry ? selectedCountry.states : []);
    setPermanentCities([]);
    setFieldValue("country", e.target.value);
    setFieldValue("state", "");
    setFieldValue("city", "");
  };

  // ------------------------------------ handle Permanent State Change functions --------------------------------

  const handlePermanentStateChange = (e, setFieldValue) => {
    const selectedState = permanentStates.find(
      (state) => state.name === e.target.value
    );
    setPermanentCities(selectedState ? selectedState.cities : []);
    setFieldValue("state", e.target.value);
    setFieldValue("city", "");
  };

  return (
    <div className="w-full  relative">
      <div className=" mobile:bg-[#fffaed] flex justify-center items-center ">
        <div className="   w-full max-w-4xl  laptop:max-w-6xl mobile:rounded-none mobile:mt-0 mobile:mb-0 mobile:p-0">
          <h2 className="text-2xl text-center  mt-7 text-[#214284] font-lora font-semibold  laptop:hidden mobile:p-0 mobile:bg-white">
            Please Fill In This KYC Form To Help Us Know You Better!
          </h2>
          <h2 className="text-2xl text-center pb-3 text-[#214284] font-lora font-semibold  mobile:hidden mobile:p-0 laptop:bg-white">
            Please Fill In This KYC Form To Help Us Know You Better!
          </h2>

          <hr className="border-t-2 opacity-16 mb-10  mobile:hidden " />

          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={(values) => {
              if (values?.addressSame === "yes") {
                values.currentAddress = values.address;
                values.currentCountry = values.country;
                values.currentState = values.state;
                values.currentCity = values.city;
                values.currentPincode = values.pincode;
              }
              localStorage.setItem("formone", JSON.stringify(values));
              onNext(values);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="space-y-6 p-8">
                {/* About Section */}
                <div className="space-y-4 mobile:p-6">
                  <div className="flex items-center space-x-2">
                    <h3 className="text-3xl font-lora font-bold mobile:mt-5 text-[#214284]">
                      About
                    </h3>
                    <hr className="flex-grow border-t-2 opacity-16 mobile:hidden" />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <label className="text-[#214284] font-poppins mt-4">
                      First Name
                      <span
                        className="text-yellow-500"
                        style={{ color: "#FFBB6B" }}
                      >
                        *
                      </span>
                      <Field
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        disabled
                      />
                    </label>
                    <label className="text-[#214284] font-poppins mt-4 ">
                      Last Name
                      <span
                        className="text-yellow-500"
                        style={{ color: "#FFBB6B" }}
                      >
                        *
                      </span>
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        disabled
                      />
                    </label>
                    <label className="text-[#214284] font-poppins mt-4 ">
                      Email
                      <span
                        className="text-yellow-500"
                        style={{ color: "#FFBB6B" }}
                      >
                        *
                      </span>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        disabled
                      />
                    </label>
                    <label
                      htmlFor="mobileNumber"
                      className="text-[#214284] font-poppins mt-4 "
                    >
                      Mobile No
                      <span
                        className="text-yellow-500"
                        style={{ color: "#FFBB6B" }}
                      >
                        *
                      </span>
                      <Field
                        type="text"
                        name="mobileNumber"
                        id="mobileNo"
                        placeholder="Mobile No"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        disabled
                      />
                    </label>
                    <label className="text-[#214284] font-poppins mt-4 ">
                      Date of Birth
                      <span
                        className="text-yellow-500"
                        style={{ color: "#FFBB6B" }}
                      >
                        *
                      </span>
                      <Field
                        type="date"
                        name="dob"
                        placeholder="DOB"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                      />
                      <ErrorMessage
                        name="dob"
                        component="span"
                        className="text-red-600 text-sm"
                      />
                    </label>
                    <label className="text-[#214284] font-poppins mt-4 mb-3">
                      Father / Spouse Name
                      <span
                        className="text-yellow-500"
                        style={{ color: "#FFBB6B" }}
                      >
                        *
                      </span>
                      <Field
                        type="text"
                        name="spouseName"
                        placeholder="Enter Name"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                      />
                      <ErrorMessage
                        name="spouseName"
                        component="span"
                        className="text-red-600 text-sm"
                      />
                    </label>
                  </div>
                </div>

                {/* Address Section */}
                <div className="mt-0 mobile:bg-[#fffaed] h-4 laptop:hidden"></div>
                <div className="mobile:p-6">
                  <div className="flex items-center space-x-2 ">
                    <h3 className="text-3xl  mb-3 font-lora font-bold text-[#214284]">
                      Address
                    </h3>
                    <hr className="flex-grow border-t-2 opacity-16 mobile:hidden" />
                  </div>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-[#214284] font-poppins">
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-3 mb-3">
                          Permanent Address
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <div>
                          <Field
                            type="text"
                            name="address"
                            placeholder="Flat No/Floor/wing/Society/Area"
                            className="mt-1 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3 mobile:w-full "
                          />
                          <Field
                            type="text"
                            name="address"
                            placeholder="Flat No/Floor/wing/Society/Area"
                            className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3 mobile:w-full tablet:hidden mobile:mt-5 laptop:hidden"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          Permanent Country
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <Field
                          as="select"
                          name="country"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                          onChange={(e) =>
                            handlePermanentCountryChange(e, setFieldValue)
                          }
                        >
                          <option value="">Select Country</option>
                          {locations.countries.map((country) => (
                            <option key={country.name} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-[#214284] font-poppins">
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          Permanent State
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <Field
                          as="select"
                          name="state"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                          onChange={(e) =>
                            handlePermanentStateChange(e, setFieldValue)
                          }
                        >
                          <option value="">Select State</option>
                          {permanentStates.map((state) => (
                            <option key={state.name} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          Permanent City
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <Field
                          as="select"
                          name="city"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        >
                          <option value="">Select City</option>
                          {permanentCities.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          Pincode
                        </label>
                        <Field
                          type="text"
                          name="pincode"
                          placeholder="Pincode"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        />
                        <ErrorMessage
                          name="pincode"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row text-[#214284] font-poppins items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                      <label className="text-[#214284] font-poppins mt-4 mb-3">
                        Is your Current Address Same as Permanent Address?
                        <span style={{ color: "#FFBB6B" }}>*</span>
                      </label>
                      <div className="flex items-center space-x-4">
                        <label className="flex items-center text-[#214284] font-poppins">
                          <Field
                            type="radio"
                            name="addressSame"
                            value="yes"
                            className="mr-2 text-secondary"
                          />
                          Yes
                        </label>
                        <label className="flex items-center">
                          <Field
                            type="radio"
                            name="addressSame"
                            value="no"
                            className="mr-2 text-secondary"
                          />
                          No, Other
                        </label>
                      </div>
                      <ErrorMessage
                        name="addressSame"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  {values.addressSame === "no" && (
                    <div className="space-y-4">
                      <div className="grid grid-cols-1 items-center md:grid-cols-2 gap-4 text-[#214284] font-poppins">
                        <div className="flex flex-col space-y-1">
                          <label
                            htmlFor="currentAddress"
                            className=" mt-4 mb-3"
                          >
                            Current Address
                          </label>
                          <Field
                            id="currentAddress"
                            name="currentAddress"
                            className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                          />
                          <ErrorMessage
                            name="currentAddress"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="flex flex-col space-y-1">
                          <label className="text-[#214284] font-poppins mt-4 mb-3">
                            Current Country
                            <span style={{ color: "#FFBB6B" }}>*</span>
                          </label>
                          <Field
                            as="select"
                            name="currentCountry"
                            className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                            onChange={(e) =>
                              handleCountryChange(e, setFieldValue)
                            }
                          >
                            <option value="">Select Country</option>
                            {locations.countries.map((country) => (
                              <option key={country.name} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="currentCountry"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-[#214284] font-poppins">
                        <div className="flex flex-col space-y-1">
                          <label className="text-[#214284] font-poppins mt-4 mb-3">
                            Current State
                            <span style={{ color: "#FFBB6B" }}>*</span>
                          </label>
                          <Field
                            as="select"
                            name="currentState"
                            className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                            onChange={(e) =>
                              handleStateChange(e, setFieldValue)
                            }
                          >
                            <option value="">Select State</option>
                            {states.map((state) => (
                              <option key={state.name} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="currentState"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="flex flex-col space-y-1">
                          <label className="text-[#214284] font-poppins mt-4 mb-3">
                            Current City
                            <span style={{ color: "#FFBB6B" }}>*</span>
                          </label>
                          <Field
                            as="select"
                            name="currentCity"
                            className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                          >
                            <option value="">Select City</option>
                            {cities.map((city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="currentCity"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="flex flex-col space-y-1">
                          <label className="text-[#214284] font-poppins mt-4 mb-3">
                            Current Pincode
                          </label>
                          <Field
                            type="text"
                            name="currentPincode"
                            placeholder="Current Pincode"
                            className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                          />
                          <ErrorMessage
                            name="currentPincode"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* other info */}
                <div className="mt-0 mobile:bg-[#fffaed] h-4 w-full laptop:hidden"></div>
                <div className="space-y-4 text-[#214284] font-poppins mobile:p-6">
                  <div className="flex items-center space-x-2">
                    <h3 className="text-3xl  mt-3 mb-3 font-lora font-bold text-[#214284]">
                      Other Info
                    </h3>
                    <hr className="flex-grow border-t-2 opacity-16 mobile:hidden" />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex flex-col space-y-1">
                      <label className="text-[#214284] font-poppins mt-4 mb-3">
                        Telegram Username{" "}
                        <span
                          className="text-yellow-500"
                          style={{ color: "#FFBB6B" }}
                        >
                          *
                        </span>
                      </label>
                      <Field
                        type="text"
                        name="telegramUsername"
                        placeholder="Enter @Username"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                      />
                      <ErrorMessage
                        name="telegramUsername"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="flex flex-col space-y-1">
                      <label className="text-[#214284] font-poppins mt-4 mb-3">
                        Referred Person Name
                      </label>
                      <Field
                        type="text"
                        name="referredPersonName"
                        placeholder="Referred Person Name"
                        className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                      />
                      <ErrorMessage
                        name="referredPersonName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col  md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                    <label className="text-[#214284] font-poppins mt-4 mb-3">
                      Do you or any family/relational associate work in the
                      field of financial markets?
                      <span style={{ color: "#FFBB6B" }}>*</span>
                    </label>
                    <div className="flex items-center space-x-4 text-[#214284] font-poppins">
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="inFinancialSector"
                          value="yes"
                          className="mr-2 text-secondary"
                        />
                        Yes
                      </label>
                      <label className="flex items-center">
                        <Field
                          type="radio"
                          name="inFinancialSector"
                          value="no"
                          className="mr-2 text-secondary"
                        />
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="inFinancialSector"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                {/* GST Section */}
                <div className="mt-0 mobile:bg-[#fffaed] h-4  laptop:hidden "></div>
                <div className="flex items-center space-x-2 mobile:p-6">
                  <h3 className="text-3xl font-lora font-bold text-[#214284]">
                    GST Registered Business
                  </h3>
                  <hr className="flex-grow border-t-2 opacity-16 font-poppins" />
                </div>
                <div className="flex flex-col font-poppins text-primary mobile: items-start mobile:p-6 mobile:space-y-0 ">
                  <label className="text-[#214284] font-poppins mb-6">
                    Do you need a GST invoice (for GST registered businesses
                    only)?
                    <span style={{ color: "#FFBB6B" }}>*</span>
                  </label>
                  <div className="flex items-center space-x-4 mobile:mt-5">
                    <label className="flex items-center">
                      <Field
                        type="radio"
                        name="gstInvoice"
                        value="yes"
                        className="mr-2 text-secondary"
                      />
                      Yes
                    </label>
                    <label className="flex items-center">
                      <Field
                        type="radio"
                        name="gstInvoice"
                        value="no"
                        className="mr-2 text-secondary"
                      />
                      No
                    </label>
                  </div>
                  <ErrorMessage
                    name="gstInvoice"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                {values.gstInvoice === "yes" && (
                  <div className="space-y-4 mobile:p-6 w-full">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-[#214284] font-poppins">
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          Company Name
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="companyName"
                          placeholder="Company Name"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        />
                        <ErrorMessage
                          name="companyName"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          Company Address
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="companyAddress"
                          placeholder="Company Address"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        />
                        <ErrorMessage
                          name="companyAddress"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <label className="text-[#214284] font-poppins mt-4 mb-3">
                          GST Number
                          <span style={{ color: "#FFBB6B" }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="gstNumber"
                          placeholder="GST Number"
                          className="mt-3 text-[#214284] font-poppins w-full border border-[#214284] rounded-[39px] shadow-sm py-3 px-3"
                        />
                        <ErrorMessage
                          name="gstNumber"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Submit Button */}
                <div className="flex justify-end mobile:justify-center items-center mobile:bg-[#fffaed] mobile:mt-7 mobile:p-0">
                  <Button label="NEXT" type="submit" variant="solid" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default KYCFormComponent;
