import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/logo.svg";
import { FiMenu, FiX } from "react-icons/fi";

const AdminHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const data = localStorage?.getItem("token");

  const handleLogin = () => {
    if (data) {
      navigate("/insiescreen");
    } else {
      navigate("/login");
    }
    setIsMenuOpen(false);
  };

  return (
    <div>
      <header className="bg-white shadow-md">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <img src={logo} alt="Logo" className="h-10" />
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              className="text-2xl ml-0 mr-0 size-1 h-9 text-[#214284]"
            >
              {isMenuOpen ? <FiX /> : <FiMenu />}
            </button>
          </div>
          <div className="hidden sm:flex space-x-4">
            <ul className="flex items-center space-x-4">
              <li onClick={handleLogin} className="mobile:w-full mobile:my-4">
                <p className="block py-2 w-full mobile:justify-center mobile:text-center cursor-pointer text-[#214284] font-[600] text-[20px] font-poppins px-5 border-[3px] border-[#214284] rounded-[35px]">
                  Portal
                </p>
              </li>

              <li>
                <Link to="">
                  <p className="block py-3 text-[#214284] font-semibold text-20 font-poppins rounded-2xl getInTouch px-5 bg-yellow-400 shadow-md">
                    Get In Touch
                  </p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {isMenuOpen && (
          <div className="sm:hidden bg-white shadow-md">
            <ul className="flex flex-col items-center space-y-4 p-4">
              <li onClick={handleLogin} className="mobile:w-full mobile:my-4">
                <p className="block py-2 w-full mobile:justify-center mobile:text-center cursor-pointer text-[#214284] font-[600] text-[20px] font-poppins px-5 border-[3px] border-[#214284] rounded-[35px]">
                  Portal
                </p>
              </li>

              <Link to={"/contactus"} className="mobile:w-full mobile:my-4">
                <li>
                  <p className="block py-3 text-[#214284] mobile:justify-center mobile:text-center font-[600] text-[20px] font-poppins getInTouch px-5">
                    Get In Touch
                  </p>
                </li>
              </Link>
            </ul>
          </div>
        )}
      </header>
    </div>
  );
};

export default AdminHeader;
